export const translateLookupQuestions = async (
  questions,
  translateTexts,
  targetLanguage
) => {
  const questionTextsToTranslate = [];
  const questionMapping = [];
  const optionNamesToTranslate = [];
  const optionMapping = [];
  const groupNamesToTranslate = [];
  const groupMapping = [];
  const subGroupNamesToTranslate = [];
  const subGroupMapping = [];
  const questionDescriptionsToTranslate = [];
  const descriptionMapping = [];

  questions.forEach((question) => {
    // Collecting questionText for translation
    if (question.questionText) {
      questionTextsToTranslate.push(question.questionText);
      questionMapping.push(question);
    }

    // Collecting groupName for translation
    if (question.groupName) {
      groupNamesToTranslate.push(question.groupName);
      groupMapping.push(question);
    }

    // Collecting subGroupName for translation
    if (question.subGroupName) {
      subGroupNamesToTranslate.push(question.subGroupName);
      subGroupMapping.push(question);
    }

    // Collecting questionDescription for translation
    if (question.questionDescription) {
      questionDescriptionsToTranslate.push(question.questionDescription);
      descriptionMapping.push(question);
    }

    // Collecting option names for translation
    if (question.questionOptions && Array.isArray(question.questionOptions)) {
      question.questionOptions.forEach((option) => {
        if (option.optionName) {
          optionNamesToTranslate.push(option.optionName);
          optionMapping.push(option);
        }
      });
    }
  });

  // Translating questionText
  if (questionTextsToTranslate.length > 0) {
    const translatedQuestionTexts = await translateTexts(
      questionTextsToTranslate,
      [],
      targetLanguage
    );
    translatedQuestionTexts.forEach((translatedText, index) => {
      const question = questionMapping[index];
      if (question) {
        question.translatedQuestionText =
          translatedText || question.questionText;
      }
    });
  }

  // Translating groupName
  if (groupNamesToTranslate.length > 0) {
    const translatedGroupNames = await translateTexts(
      groupNamesToTranslate,
      [],
      targetLanguage
    );
    translatedGroupNames.forEach((translatedGroupName, index) => {
      const question = groupMapping[index];
      if (question) {
        question.translatedGroupName =
          translatedGroupName || question.groupName;
      }
    });
  }

  // Translating subGroupName
  if (subGroupNamesToTranslate.length > 0) {
    const translatedSubGroupNames = await translateTexts(
      subGroupNamesToTranslate,
      [],
      targetLanguage
    );
    translatedSubGroupNames.forEach((translatedSubGroupName, index) => {
      const question = subGroupMapping[index];
      if (question) {
        question.translatedSubGroupName =
          translatedSubGroupName || question.subGroupName;
      }
    });
  }

  // Translating questionDescription
  if (questionDescriptionsToTranslate.length > 0) {
    const translatedDescriptions = await translateTexts(
      questionDescriptionsToTranslate,
      [],
      targetLanguage
    );
    translatedDescriptions.forEach((translatedDescription, index) => {
      const question = descriptionMapping[index];
      if (question) {
        question.translatedQuestionDescription =
          translatedDescription || question.questionDescription;
      }
    });
  }

  // Translating option names
  if (optionNamesToTranslate.length > 0) {
    const translatedOptionNames = await translateTexts(
      optionNamesToTranslate,
      [],
      targetLanguage
    );
    translatedOptionNames.forEach((translatedOptionName, index) => {
      const option = optionMapping[index];
      if (option) {
        option.translatedOptionName = translatedOptionName || option.optionName;
      }
    });
  }

  return questions; // Returning the translated questions
};
