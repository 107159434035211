<template>
  <div
    class="modal fade consentToContactModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>{{ translatedData?.title }}</h2>
          </div>
          <div class="content-desc">
            <!-- <p>
              By clicking "Continue”, you agree that
              <strong>{{ loCompany }}</strong> and its current and future
              affiliates, successors, and assignees may call/text you at the
              phone number(s) provided about <strong>{{ loCompany }}</strong
              >’s services and products, which may involve use of automated
              means and prerecorded/artificial voices. You do not need to
              consent as a condition of buying any property, goods or services.
              Message/data rates may apply.
            </p> -->
            <p>
              {{ translatedData?.paragraph1 }}
              {{ loCompany }} {{ translatedData?.paragraph2 }}
              <a href="tel:615.933.2080">{{ translatedData?.phoneNumber }}</a
              >{{ translatedData?.paragraph3 }}
              <a href="info@candid.inc">info@candid.inc</a
              >{{ translatedData?.optOutInfo }}
              <strong>{{ translatedData?.strengthMessage }}</strong>
              {{ translatedData?.callRecordingConsent }}
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>{{ translatedData?.closeButton }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { translationMixin } from "../../mixins/translationMixin";
const jsonData = {
  title: "Consent to Contact",
  paragraph1:
    "By submitting your phone number and clicking the Submit button, you are providing express written consent for",
  paragraph2:
    "and its affiliates, agents, and service providers to contact you at that number regarding products or services, including via autodialed and/or prerecorded or artificial voice calls and text messages (SMS and MMS), or email even if your telephone number is a cellular number or on a corporate, state, or the National Do Not Call Registry (DNC) or other do not contact list. You may also reach us at",
  phoneNumber: "615.933.2080",
  paragraph3:
    ". You may opt-out at any time by emailing us a Do Not Call Request at",
  optOutInfo: " and providing your phone number.",
  strengthMessage:
    "Your consent is not required or a condition of any purchase. Message and data rates may apply.",
  callRecordingConsent:
    "By communicating with us by phone you consent to calls being recorded and monitored.",
  closeButton: "Close",
};
export default {
  name: "ConsetToContactModal",
  mixins: [translationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: jsonData, // Define consentRecordData here
    };
  },
  computed: {
    ...mapGetters(["getCompanyName", "getUser"]),

    loCompany() {
      return this.getCompanyName.replaceAll("-", " ");
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>
