<template>
  <div
    class="modal fade consentToUseRecordsModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>
              {{ translatedData?.modal_title }}
            </h2>
          </div>
          <div class="content-desc">
            <p>
              <strong>
                {{ translatedData?.introduction }}
              </strong>
            </p>
            <p>
              <a name="_Hlk80088942"></a>
              {{ translatedData?.definitions }}
              <em
                ><strong>{{ translatedData?.we }}</strong></em
              >,
              <em
                ><strong>{{ translatedData?.our }}</strong></em
              >, {{ translatedData?.and }}
              <em
                ><strong>{{ translatedData?.us }}</strong></em
              >
              {{ translatedData?.means_your_financial }}
              {{ translatedData?.means_your_financial_1 }}
              <em
                ><strong>{{ translatedData?.you }}</strong></em
              >
              {{ translatedData?.and }}
              <em
                ><strong>{{ translatedData?.your }}</strong></em
              >
              {{ translatedData?.means_your_financial_2 }}
              {{ translatedData?.means_your_financial_3 }}
            </p>
            <p>{{ translatedData?.scope_of_eConsent }}</p>
            <p>
              {{ translatedData?.scope_of_eConsent_description_1 }}
              <strong>
                {{ translatedData?.scope_of_eConsent_description_2 }}
              </strong>
            </p>
            <p>{{ translatedData?.right_to_paper_version }}</p>
            <p>
              {{ translatedData?.right_to_paper_version_description }}
            </p>
            <p>
              {{ translatedData?.right_to_paper_version_description_1 }}
            </p>
            <p>
              {{ translatedData?.right_to_paper_version_description_2 }}
            </p>
            <ul>
              <li>
                <p>
                  {{ translatedData?.paper_request_contact }}
                  {{ loCompany }} {{ translatedData?.at }} {{ getUser.phone }},
                  {{ getUser.email }}.
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.paper_request_contact_1 }}
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.paper_request_contact_2 }}
            </p>
            <p>{{ translatedData?.right_to_withdraw_eConsent }}</p>
            <p>
              {{ translatedData?.right_to_withdraw_eConsent_description }}
            </p>
            <ul>
              <li>
                <p>
                  {{ translatedData?.withdraw_eConsent_contact }}
                  {{ loCompany }} {{ translatedData?.at }} {{ getUser.phone }},
                  {{ getUser.email }}.
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.withdraw_eConsent_contact_1 }}
                  <a href="mailto:info@candid.inc">info@candid.inc</a>.
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.withdraw_effective }}
            </p>
            <p>{{ translatedData?.obligation_update_email }}</p>
            <p>
              <strong>
                {{ translatedData?.obligation_update_email_description }}
              </strong>
              <strong>
                {{ loCompany }} {{ translatedData?.at }} {{ getUser.phone }},
                {{ getUser.email }} </strong
              ><em
                ><strong>{{ translatedData?.and }}</strong></em
              >
              <strong>
                {{ translatedData?.contact_candid }}
                {{ translatedData?.at }} info@candid.inc.</strong
              >
            </p>
            <p>{{ translatedData?.hardware_software_requirements }}</p>
            <p>
              {{ translatedData?.hardware_software_requirements_description }}
            </p>
            <ul>
              <li>
                <p>{{ translatedData?.internet_access }}</p>
              </li>
              <li>
                <p>
                  {{ translatedData?.disc_space }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.pdf_reader }}
                  <a href="https://get.adobe.com/reader/">
                    https://get.adobe.com/reader/
                  </a>
                  .
                  <a
                    href="https://files.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf"
                    target="_blank"
                  >
                    <strong>{{ translatedData?.pdf_reader_1 }}</strong>
                  </a>
                  {{ translatedData?.pdf_reader_2 }}
                </p>
              </li>
              <li>
                <p>{{ translatedData?.printer }}</p>
              </li>
              <li>
                <p>{{ translatedData?.ssl_encryption }}</p>
              </li>
              <li>
                <p>
                  {{ translatedData?.email_address }}
                </p>
              </li>
              <li>
                <p>{{ translatedData?.javascript_enabled }}</p>
              </li>
              <li>
                <p>{{ translatedData?.security_settings_enabled }}</p>
              </li>
              <li>
                <p>
                  {{ translatedData?.webcam_required }}
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.desktop_requirements }}
            </p>
            <ul>
              <li>
                <p>
                  {{ translatedData?.desktop_os }}
                  <strong>{{ translatedData?.windows }}</strong>
                  {{ translatedData?.or }}
                  <strong>{{ translatedData?.mac_os }}</strong>
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.desktop_browser }}
                  <strong> {{ translatedData?.chrome }}</strong
                  >,<strong> {{ translatedData?.mie }}</strong
                  >, <strong> {{ translatedData?.edge }}</strong
                  >, <strong> {{ translatedData?.mozilla }}</strong
                  >, {{ translatedData?.or }}
                  <strong> {{ translatedData?.safari }}</strong>
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.tablet_mobile_requirements }}
            </p>
            <ul>
              <li>
                <p>
                  {{ translatedData?.tablet_mobile_requirements_1 }},
                  <a
                    href="https://support.apple.com/guide/iphone/supported-models-iphe3fa5df43/13.0/ios/13.0"
                    target="_blank"
                  >
                    <strong>{{
                      translatedData?.tablet_mobile_requirements_2
                    }}</strong>
                  </a>
                  , {{ translatedData?.tablet_mobile_requirements_3 }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.tablet_mobile_ipad_1 }},
                  <a
                    href="https://support.apple.com/guide/ipad/supported-models-ipad213a25b2/ipados"
                    target="_blank"
                  >
                    <strong>{{ translatedData?.tablet_mobile_ipad_2 }}</strong>
                  </a>
                  , {{ translatedData?.tablet_mobile_ipad_3 }}
                </p>
              </li>
              <li>
                <p>{{ translatedData?.tablet_mobile_android }}</p>
              </li>
              <li>
                <p>
                  {{ translatedData?.tablet_mobile_browser }}
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.platform_optimization }}
              <a href="https://www.google.com/chrome/" target="_blank">
                <u>https://www.google.com/chrome/</u>
              </a>
              {{ translatedData?.platform_optimization_1 }}
            </p>
            <p>
              {{ translatedData?.changes_to_notice }}
            </p>
            <p>
              {{ translatedData?.changes_to_notice_description }}
            </p>
            <p>{{ translatedData?.obligation_change_device }}</p>
            <p>
              <strong>
                {{ translatedData?.obligation_change_device_description }}
              </strong>
            </p>
            <p>
              {{ translatedData?.consent_agreement }}
            </p>
            <ul>
              <li>
                <p>{{ translatedData?.consent_agreement_1 }}</p>
              </li>
              <li>
                <p>
                  {{ translatedData?.consent_agreement_2 }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.consent_agreement_3 }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.consent_agreement_4 }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.consent_agreement_5 }}
                </p>
              </li>
            </ul>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>{{ translatedData?.close_button }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import consentRecordJson from "../../data/consentRecord";
import { translationMixin } from "../../mixins/translationMixin";
export default {
  name: "ConsentToUseModal",
  mixins: [translationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: consentRecordJson, // Define consentRecordData here
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    loCompany() {
      return this.getUser.LOCompany.replace("-", " ");
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>
