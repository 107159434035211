import { mapGetters } from "vuex";
// import { translateText } from '../translate-service';
import { translateTexts } from "../translate-service-new";

export const translationMixin = {
  data() {
    return {
      translatedData: null, // This will hold the translated data
    };
  },
  computed: {
    ...mapGetters([
      "getAppLanguage", // Get the app language from Vuex store
    ]),
  },
  watch: {
    getAppLanguage: {
      immediate: true,
      deep: true,
      async handler() {
        // Translate when language changes
        await this.fetchTranslatedData();
      },
    },
  },
  methods: {
    // Pass translateText as a prop or import it here
    translateTexts,
    // Fetch data (privacyData, termsData, etc.) and translate it
    async fetchTranslatedData() {
      const targetLanguage = this.getAppLanguage;
      const dataToTranslate = this.getDataToTranslate();
      this.translatedData = { ...dataToTranslate }; // Make a copy of the data to avoid modifying the original

      try {
        const valuesToTranslate = Object.values(this.translatedData).filter(
          (value) => typeof value === "string" && value.trim() !== ""
        );

        // Split the values into batches of 128 (you can adjust this number)
        const batchSize = 128;
        const batchedTexts = [];

        for (let i = 0; i < valuesToTranslate.length; i += batchSize) {
          batchedTexts.push(valuesToTranslate.slice(i, i + batchSize));
        }

        // Perform translations in batches
        let translatedValues = [];
        for (const batch of batchedTexts) {
          const batchTranslation = await translateTexts(
            batch,
            [],
            targetLanguage
          );
          translatedValues = [...translatedValues, ...batchTranslation];
        }

        // Now assign the translated values back to their respective keys in translatedData
        let valueIndex = 0;
        for (const key of Object.keys(this.translatedData)) {
          const value = this.translatedData[key];
          if (typeof value === "string" && value.trim() !== "") {
            this.$set(
              this.translatedData,
              key,
              translatedValues[valueIndex] || value
            ); // Use translated value or fallback to original
            valueIndex++;
          }
        }
      } catch (error) {
        console.error("Error translating data:", error);
      }
    },
    // Method to retrieve the data (e.g., privacyData, termsData, etc.)
    getDataToTranslate() {
      return this.data; // This should be overridden in each component
    },
  },
};
