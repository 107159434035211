var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showFields)?_c('address-fields',{attrs:{"question":_vm.question,"currentaq":_vm.currentaq,"previousaq":_vm.previousaq,"addressId":_vm.currentAddressId,"userAddresses":_vm.userAddresses}}):_vm._e(),(!_vm.showFields)?_c('form',{staticClass:"property"},[_c('div',{staticClass:"question"},[(_vm.getQueId)?_c('h2',[_vm._v(_vm._s(_vm.getUserNameWithQueText))]):_c('h2',[_vm._v(_vm._s(_vm.questionGName))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.question.questionDescription),expression:"question.questionDescription"}],staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.questionDesc)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.question.subGroupName),expression:"question.subGroupName"}],staticClass:"personal-info mt-3"},[_vm._v(" "+_vm._s(_vm.questionSubGName)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.getActiveSection === 'Co-Applicant Info' && !_vm.userAddresses.length
      ),expression:"\n        getActiveSection === 'Co-Applicant Info' && !userAddresses.length\n      "}],staticClass:"badge bg-dynamic mb-2 cursor-pointer",on:{"click":_vm.copyApplicantsAddress}},[_vm._v(" "+_vm._s(_vm.translatedData?.copy)+" "+_vm._s(_vm.getApplicantName)+"'"+_vm._s(_vm.translatedData?.adressHistory)+" ")]),_c('ul',[_vm._l((_vm.translatedUserAddresses),function(address,index){return _c('li',{key:index},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.formTheAddress(address.addressData)))]),(
              index === 0 &&
              (_vm.getActiveSection === 'Personal Information' ||
                _vm.getActiveSection === 'Co-Applicant Info' ||
                _vm.getActiveSection === 'Real Estate Owned')
            )?_c('span',{staticClass:"text-red"},[_c('strong',[_vm._v(_vm._s(_vm.translatedData?.currentAddress))])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSubjectProperty(address)),expression:"showSubjectProperty(address)"}],staticClass:"badge bg-success mb-2"},[_vm._v(" "+_vm._s(_vm.translatedData?.subjectProperty)+" ")]),(_vm.getActiveSection === 'Real Estate Owned')?_c('p',[(_vm.reOrderQueForREO(address.addressData))?_c('span',[_vm._v(_vm._s(address.addressData[_vm.reOrderQueForREO(address.addressData)] .questionText)+": "),_c('strong',[_vm._v(_vm._s(address.addressData[_vm.reOrderQueForREO(address.addressData)] .questionAnswer))])]):_vm._e()]):_vm._e(),_vm._l((address.addressData),function(subs,i){return _c('p',{key:i},[(
              subs.questionType === 'Picklist' &&
              subs.questionFieldMapping !==
                'currently_making_monthly_payments__c' &&
              subs.questionFieldMapping !==
                'candidoem__currently_making_monthly_payments__c' &&
              subs.questionFieldMapping !== 'occupancy__c' &&
              subs.questionFieldMapping !== 'candidoem__occupancy__c'
            )?_c('span',[_vm._v(" "+_vm._s(subs?.translatedQuestionText)+": "),_c('strong',[_vm._v(_vm._s(subs?.questionAnswer))])]):(
              (subs.questionFieldMapping === 'move_in_date__c' ||
                subs.questionFieldMapping === 'candidoem__move_in_date__c') &&
              subs.questionAnswer
            )?_c('span',[_vm._v(_vm._s(subs?.translatedQuestionText)+": "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(subs?.questionAnswer)))])]):(
              (subs.questionFieldMapping === 'monthly_rent__c' ||
                subs.questionFieldMapping === 'candidoem__monthly_rent__c') &&
              subs.questionAnswer &&
              index === 0 &&
              _vm.getActiveSection === 'Personal Information'
            )?_c('span',[_vm._v(_vm._s(subs?.translatedQuestionText)+": "),_c('strong',[_vm._v(_vm._s(Number(subs.questionAnswer).toLocaleString("en-US", { style: "currency", currency: "USD", })))])]):_vm._e()])}),_c('div',{staticClass:"edit-delete mt-3"},[_c('a',{staticClass:"btn-icon edit cursor-pointer",attrs:{"data-bs-toggle":"modal","data-bs-target":`#deleteModal${address.addressId}`}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v("   "+_vm._s(_vm.translatedData?.delete))]),_vm._v("    "),_c('a',{staticClass:"btn-icon edit cursor-pointer",on:{"click":function($event){_vm.showFields = true;
              _vm.currentAddressId = address.addressId;}}},[_c('i',{staticClass:"fa fa-pencil-alt"}),_vm._v("   "+_vm._s(_vm.translatedData?.edit)+" ")])]),_c('div',{staticClass:"modal fade",attrs:{"id":`deleteModal${address.addressId}`,"data-bs-backdrop":"static","data-bs-keyboard":"false","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title text-red",attrs:{"id":"staticBackdropLabel"}},[_vm._v(" "+_vm._s(_vm.translatedData?.warning)+" ")])]),_c('div',{staticClass:"modal-body"},[_vm._v(" "+_vm._s(_vm.translatedData?.isDelete)+" "),_c('p',{staticClass:"text-primary mt-3"},[_vm._v(" "+_vm._s(_vm.formTheAddress(address.addressData))+" ")])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.translatedData?.close)+" ")]),_c('button',{staticClass:"btn btn-red",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":function($event){return _vm.deleteTheAddress(address)}}},[_c('span',[_vm._v(_vm._s(_vm.translatedData?.delete))])])])])])])],2)}),(_vm.showAnotherPropertyButton)?_c('li',[_c('div',{staticClass:"title"},[_c('button',{staticClass:"d-flex align-items-center",attrs:{"type":"button"},on:{"click":function($event){_vm.showFields = true;
              _vm.currentAddressId = '';}}},[_vm._m(0),(_vm.getActiveSection === 'Personal Information')?_c('h2',{staticClass:"mb-0"},[_vm._v(" Add a previous address ")]):_c('h2',{staticClass:"mb-0"},[_vm._v("Add another property")])])])]):_vm._e()],2),_c('div',{staticClass:"button mt-4 pt-3"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button"},on:{"click":_vm.previousQuestion}},[_c('span',[_vm._v(_vm._s(_vm.translatedData?.previous))])]),_c('button',{staticClass:"btn btn-red",attrs:{"type":"button"},on:{"click":_vm.nextQuestion}},[_c('span',[_vm._v(_vm._s(_vm.translatedData?.continue))])])]),(_vm.question.questionLink)?_c('a',{staticClass:"text-underline",attrs:{"data-bs-toggle":_vm.question.questionLink && _vm.question.questionLinkDescription
          ? 'modal'
          : '',"data-bs-target":"#infoModal"}},[_vm._v(_vm._s(_vm.translatedData?.questionLink))]):_vm._e(),(_vm.question.questionLink && _vm.question.questionLinkDescription)?_c('info-modal',{attrs:{"title":_vm.translatedData?.questionLink,"description":_vm.translatedData?.questionLinkDescription,"close":_vm.translatedData?.close}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plus-icon"},[_c('i',{staticClass:"fa fa-plus"})])
}]

export { render, staticRenderFns }