import axios from './api-service';

const apiKey = 'AIzaSyCZDzkAn6CYRk0M_GygBrJc8ETq-BaXpb0'; // Replace with your actual API key

async function translateTexts(data, keysToTranslate = [], targetLang = 'en') {
    // Early exit for null values
    if (data === null || data === undefined) {
        return data;
    }

    // Translate standalone strings
    if (typeof data === 'string') {
        return await translateString([data], targetLang); // Pass the string in an array for batch processing
    } 

    // Handle arrays: batch translate each element
    if (Array.isArray(data)) {
        const textsToTranslate = data.map(item => (typeof item === 'string' ? item : null)).filter(Boolean); // Collect only strings for translation
        const translatedTexts = await translateString(textsToTranslate, targetLang);

        // Assign the translated values back to their original positions
        return data.map((item, index) => {
            return typeof item === 'string' ? translatedTexts[index] : item;  // Only replace strings
        });
    } 

    // Handle objects: translate specific keys
    if (typeof data === 'object' && data !== null) {
        const translatedObject = { ...data }; // Copy the original object

        // Translate only the specified keys if they exist
        for (const key of keysToTranslate) {
            if (key in data && typeof data[key] === 'string') {
                translatedObject[key] = await translateString([data[key]], targetLang); // Batch process the single string
            }
        }

        return translatedObject;
    }

    // Return other non-translatable data types as-is
    return data;
}

// This function now handles both single and batch translation
async function translateString(texts, targetLang) {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    if (!texts || texts.length === 0) {
        return texts;  // Return as is if no texts are provided
    }

    try {
        if ("en" === targetLang) {
          return texts;
        } else {
          const response = await axios.post(url, {
            q: texts, // Send an array of texts
            source: "en",
            target: targetLang,
          });
    
          // Return the translated texts as an array
          return response?.data?.data?.translations?.map(
            (translation) => translation?.translatedText
          );
        }
      } catch (error) {
        console.error('Translation error:', error);
        return texts;  // Fallback to original texts if translation fails
    }
}

export { translateTexts };
