<template>
  <div>
    <div class="form-group">
      <div class="question">
        <h2>{{ questionTitle }}</h2>
        <p
          class="mt-3"
          v-show="question.questionDescription"
          v-html="question.questionDescription"
        ></p>
      </div>
      <div class="answer" v-if="question.questionOptions.length">
        <ul :class="question.questionOptions.length === 3 ? 'flex-3' : ''">
          <li v-for="(option, index) in answerOptions" :key="index">
            <input
              type="radio"
              :id="option.optionValue"
              :value="option.optionValue"
              v-model.trim="lookUpAnswer"
            />
            <label :for="option.optionValue">
              <img
                v-if="option.optionSvgLink"
                class="dynamic-color-icons"
                :src="option.optionSvgLink"
              />
              <span>{{ option.optionName }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade">
      <div class="mt-4 form-group" v-if="showFields">
        <div class="question">
          <h2>{{ linkedQuestions[0]?.translatedGroupName }}</h2>
          <p
            class="mt-3"
            v-show="linkedQuestions[0].questionDescription"
            v-html="linkedQuestions[0].questionDescription"
          ></p>
          <p class="personal-info mt-3">
            {{ linkedQuestions[0]?.translatedSubGroupName }}
          </p>
        </div>
        <div class="row">
          <div class="mb-3 col-sm-6">
            <label for="Last Name" class="form-label"
              >{{ translatedData?.lastName }}
              <small class="asterisk-mark">*</small>
            </label>
            <div class="input-group">
              <input
                v-model.trim="lastNameSearch"
                class="form-control px-3"
                type="text"
                placeholder="Last Name"
                @input="matchAgent(lastNameSearch)"
              />
              <div class="input-group-append">
                <span
                  @click="eraseAgent()"
                  class="input-group-text bg-transparent"
                  ><i v-show="lastNameSearch" class="fas fa-times"></i
                ></span>
              </div>
            </div>
            <div class="dropdown" v-show="linkedInput">
              <div class="dropdown-list">
                <div
                  v-for="(so, k) in searchedAgents"
                  :key="k"
                  class="dropdown-item"
                  @click="selectAgent(so)"
                >
                  {{ so.Name }}
                </div>
                <div
                  class="dropdown-item"
                  @click="
                    hideAgentDetails = false;
                    linkedInput = false;
                  "
                >
                  + {{ translatedData?.addNewAgent }}: &nbsp;<strong>{{
                    lastNameSearch
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(que, index) in linkedQuestions"
            :key="index"
            :class="que.questionCSSClass"
          >
            <transition name="fade">
              <div v-show="!hideAgentDetails" class="form-group">
                <!-- Label for Input Types except for Checkbox type -->
                <label
                  :for="que?.questionText"
                  class="form-label"
                  v-if="que.questionType !== 'Checkbox'"
                  >{{ que?.translatedQuestionText }}
                  <small v-if="que.isMandatory" class="asterisk-mark">*</small>
                </label>

                <!-- Type: CheckBox -->
                <template v-if="que.questionType === 'Checkbox'">
                  <div class="checkbox">
                    <input
                      :type="getFieldType(que)"
                      :id="que.questionText"
                      v-model.trim="theAnswers[index]"
                      :disabled="checkLookUpAnswer"
                    />
                    <label :for="que.questionText"
                      >{{ que?.translatedQuestionText }} &nbsp;
                      <small v-if="que.isMandatory" class="asterisk-mark"
                        >*</small
                      ></label
                    >
                  </div>
                </template>

                <!-- Type: Picklist -->
                <template v-else-if="que.questionType === 'Picklist'">
                  <div class="answer">
                    <div class="d-flex">
                      <div
                        class="small-radio pe-3"
                        v-for="(option, i) in que.questionOptions"
                        :key="i"
                      >
                        <input
                          type="radio"
                          :id="option.optionValue + que.questionId"
                          :value="option.optionValue"
                          v-model.trim="theAnswers[index]"
                          :disabled="checkLookUpAnswer"
                        />
                        <label :for="option.optionValue + que.questionId">
                          <img
                            v-if="option.optionSvgLink"
                            class="dynamic-color-icons"
                            :src="option.optionSvgLink"
                          />
                          <span>{{ option.optionName }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </template>

                <!-- Type: Dropdown -->
                <template v-else-if="que.questionType === 'Dropdown'">
                  <select
                    class="form-select"
                    v-model.trim="theAnswers[index]"
                    :disabled="checkLookUpAnswer"
                  >
                    <option
                      v-for="(option, index) in que.questionOptions"
                      :key="index"
                    >
                      {{ option.optionName }}
                    </option>
                  </select>
                </template>

                <!-- Type: Input Fields -->
                <template v-else>
                  <div
                    v-if="inputFieldsWithIcons.includes(que.questionType)"
                    class="input-group"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" :id="'basic-addon' + index"
                        ><i :class="getIcons(que.questionType)"></i
                      ></span>
                    </div>
                    <input
                      :type="getFieldType(que)"
                      :inputmode="getInputMode(que)"
                      class="form-control"
                      :id="que.questionText"
                      :placeholder="que?.translatedQuestionText"
                      v-model.trim="theAnswers[index]"
                      :disabled="checkLookUpAnswer"
                    />
                    <div
                      class="input-group-append"
                      v-if="
                        getFieldType(que) === 'password' ||
                        getFieldType(que) === 'show-password'
                      "
                    >
                      <span
                        @click="showInput(index)"
                        class="input-group-text input-group-eye bg-transparent"
                        ><i
                          :class="
                            getFieldType(que) === 'show-password'
                              ? 'far fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>

                  <input
                    v-else
                    :type="getFieldType(que)"
                    :inputmode="getInputMode(que)"
                    class="form-control"
                    @blur="
                      que.questionType === 'Phone'
                        ? maskNumber($event, index)
                        : ''
                    "
                    :id="que.questionText"
                    :placeholder="que?.translatedQuestionText"
                    v-model.trim="theAnswers[index]"
                    :disabled="checkLookUpAnswer"
                    @input="
                      que.questionType === 'Email'
                        ? checkEmail(theAnswers[index])
                        : que.questionType === 'Phone'
                        ? maskNumber($event, index)
                        : ''
                    "
                  />
                  <small
                    class="text-danger"
                    v-if="que.questionType === 'Email' && !validEmail"
                  >
                    {{ translatedData?.emailValidation }}</small
                  >
                  <small
                    class="text-danger"
                    v-if="que.questionType === 'Phone' && !validPhoneNumber"
                  >
                    {{ translatedData?.mobileValidation }}</small
                  >
                </template>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>

    <div class="button mt-4 justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>{{ translatedData?.previous }}</span>
        </button>
        <button
          type="button"
          class="btn"
          :disabled="!checkTheValidation"
          :class="checkTheValidation ? 'btn-red' : ''"
          @click="saveTheData"
        >
          <span>{{ translatedData?.continue }}</span>
        </button>
      </div>

      <div class="align-text-right col-sm-6">
        <a
          v-if="question.questionLink"
          :data-bs-toggle="
            question.questionLink && question.questionLinkDescription
              ? 'modal'
              : ''
          "
          data-bs-target="#infoModal"
          class="text-underline"
          >{{ translatedData?.questionLink }}</a
        >
      </div>
      <info-modal
        v-if="question.questionLink && question.questionLinkDescription"
        :title="translatedData?.questionLink"
        :description="translatedData?.questionLinkDescription"
        :close="translatedData?.close"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import commonFunctions from "./../../mixins/commonfunctions";
import { translationMixin } from "../../mixins/translationMixin";
import { translateLookupQuestions } from "../../utilis/translationLookupField";
import InfoModal from "../InfoModal.vue";

export default {
  name: "LookUp",
  mixins: [commonFunctions, translationMixin],
  components: { InfoModal },
  data() {
    return {
      lookUpAnswer: "",
      linkedQuestions: [],
      theAnswers: [],
      theAgents: [],
      searchedAgents: [],
      linkedInput: false,
      showFields: false,
      theAgentId: "",
      lastNameSearch: "",
      hideAgentDetails: true,
      data: {
        lastName: "Last Name",
        addNewAgent: "Add a new agent",
        emailValidation: "Please enter a valid email address.",
        mobileValidation: "Please enter a 10 digit mobile number.",
        continue: "Continue",
        previous: "Previous",
        close: "close",
        questionLink: this.question?.questionLink,
        questionLinkDescription: this.question?.questionLinkDescription,
      },
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  watch: {
    lookUpAnswer: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          let check = this.linkedQuestions.every(
            (x) => x.dependentQuestionAnswer === newVal
          );

          if (check) {
            this.prePopulateData();
            this.showFields = true;
          }

          if (!check) {
            this.showFields = false;
            this.theAnswers = [];
          }
        }
      },
    },
    getAppLanguage: {
      immediate: true,
      deep: true,
      async handler() {
        let targetLanguage = this.getAppLanguage;
        await translateLookupQuestions(
          this.linkedQuestions,
          this.translateTexts,
          targetLanguage
        );
      },
    },
  },
  computed: {
    ...mapGetters(["getAppLanguage"]),
    checkLookUpAnswer() {
      return (
        this.theAgentId !== "" &&
        this.theAgentId !== undefined &&
        this.theAgentId !== null
      );
    },

    checkTheValidation() {
      if (this.showFields) {
        let theArr = [];
        let ques = this.linkedQuestions;
        let answers = this.theAnswers;

        for (let i = 0; i < ques.length; i++) {
          if (
            ques[i].isMandatory &&
            (answers[i] === undefined ||
              answers[i] === "" ||
              answers[i] === null)
          )
            theArr.push(true);
          else if (ques[i].questionType === "Checkbox" && answers[i] === false)
            theArr.push(true);
          else if (
            ques[i].questionType === "Date" &&
            answers[i] === "Invalid date"
          )
            theArr.push(true);
          else theArr.push(false);
        }

        return (
          theArr.every((v) => v === false) &&
          this.validEmail &&
          this.validPhoneNumber
        );
      }

      return this.lookUpAnswer ? true : false;
    },
  },
  mounted() {
    this.checkTheDependancy(this.question);

    if (this.question.questionAnswer === "No") {
      this.lookUpAnswer = "No";
    } else if (this.question.questionAnswer === null) {
      this.lookUpAnswer = "";
    } else {
      this.lookUpAnswer = "Yes";
      this.theAgentId = this.question.questionAnswer;
    }

    this.getAgents();
    this.getLinkedQuestions();
  },
  methods: {
    maskNumber(event, index) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (this.theAnswers[index] && this.theAnswers[index].length) {
        let x = this.theAnswers[index]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        this.theAnswers[index] =
          x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }

      this.checkPhoneNumber(this.theAnswers[index]);
    },

    showInput(index) {
      let queType = this.linkedQuestions[index].questionType;
      this.linkedQuestions[index].questionType =
        queType === "Password" ? "Show-Password" : "Password";
    },

    async getAgents() {
      this.fetchCompLoaderStatus(true);

      await this.$http
        .get(
          "/agent/getAgents?orgId=" +
            this.getOrgId +
            "&loId=" +
            this.getProspectId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.theAgents = resp.agentDetails;
            this.prePopulateData();
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },

    async getLinkedQuestions() {
      let theFields = [];

      for (let i = this.getActiveQuestion; i < this.getQuestions.length; i++) {
        if (this.getQuestions[i].apiName !== "AgentAPI") {
          break;
        }

        theFields.push(this.getQuestions[i]);
      }

      this.linkedQuestions = theFields;
      let targetLanguage = this.getAppLanguage;
      await translateLookupQuestions(
        this.linkedQuestions,
        this.translateTexts,
        targetLanguage
      );
    },

    prePopulateData() {
      if (this.checkLookUpAnswer) {
        if (this.theAgents?.length) {
          let agent = this.theAgents.find((v) => v.Id === this.theAgentId);

          if (agent) {
            if (agent.Name === "No Agent") {
              this.lookUpAnswer = "No";
              this.theAgentId = "";
            } else {
              this.selectAgent(agent);
            }
          }
        }
      } else {
        this.theAgentId = "";
      }
    },

    matchAgent(option) {
      if (option.length > 1) {
        if (this.theAgents?.length) {
          this.linkedInput = true;

          let theList = this.theAgents.filter((v) =>
            v.Name.toLowerCase().includes(option.toLowerCase())
          );

          if (theList.length) {
            this.searchedAgents = theList;
            this.hideAgentDetails = true;
          } else {
            this.searchedAgents = [];
            this.theAgentId = "";
            this.linkedInput = false;
            this.hideAgentDetails = false;

            this.linkedQuestions.forEach((v, index) => {
              this.$set(this.theAnswers, index, "");
            });
          }
        } else {
          this.linkedInput = false;
        }
      }

      if (!option.length) {
        this.theAnswers = [];
        this.theAgentId = "";
        this.linkedInput = false;
        return;
      }
    },

    selectAgent(option) {
      if (option) {
        this.hideAgentDetails = false;

        this.linkedQuestions.forEach((v, index) => {
          let key = v.questionFieldMapping;
          let answer;

          if (key.includes("candidoem") && key.includes("email")) {
            key = key.replace("candidoem", (letter) => letter.toUpperCase());
            key = key.replaceAll("e", (letter) => letter.toUpperCase());

            answer = option[key.charAt(0).toUpperCase() + key.slice(1)];
          } else {
            answer = option[key.charAt(0).toUpperCase() + key.slice(1)];
          }

          if (index === 0) {
            this.lastNameSearch = answer.split(" ")[1] || answer.split(" ")[0];
            this.$set(this.theAnswers, index, answer.split(" ")[0]);
          } else {
            this.$set(this.theAnswers, index, answer);
          }
        });

        this.theAgentId = option["Id"];
        this.linkedInput = false;
      }
    },

    async saveTheData() {
      this.fetchCompLoaderStatus(true);

      if (this.showFields && !this.checkLookUpAnswer) {
        this.linkedQuestions.forEach((v, index) => {
          if (index === 0) {
            v.questionAnswer =
              this.theAnswers[index] + " " + this.lastNameSearch;
          } else {
            v.questionAnswer = this.theAnswers[index];
          }
        });

        let payLoad = {
          accountToInsert: this.linkedQuestions,
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/agent/createAgentClone?loId=" + this.getProspectId, payLoad)
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              this.theAgentId = resp?.agentDetails[0]?.Id;
              await this.callTheMainAPI();
            }

            if (resp.response === "Error") {
              this.err(resp.messsage);
              this.fetchCompLoaderStatus(false);
            }
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
            this.fetchCompLoaderStatus(false);
          });
      } else {
        await this.callTheMainAPI();
      }
    },

    async callTheMainAPI() {
      let payLoad = {};

      let answer = this.lookUpAnswer === "Yes" ? this.theAgentId : "No";

      payLoad = {
        question_id: this.question.questionId,
        answer: answer,
      };

      this.addAnswerLocally(payLoad);

      if (!this.getUserId) this.nextQuestion();

      if (this.getUserId && this.question.apiName === "LockLearProspectAuth") {
        let theQuestion = this.question;
        theQuestion.questionAnswer = answer;

        // payLoad for API
        payLoad = {
          prospectId: this.getUserId,
          questionsToUpdate: [theQuestion],
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/question/prospect/Questions/", payLoad)
          .then((response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              this.updateQueObjectWithAnswer(resp.appQuestions);
              this.nextQuestion();
            }

            if (resp.response === "Error") {
              this.err(resp.message);
            }
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      this.fetchCompLoaderStatus(false);
    },

    nextQuestion() {
      if (this.getActiveQuestion <= this.getQuestions.length)
        this.fetchActiveQuestion(this.linkedQuestions.at(-1).questionIndex + 1);
    },

    eraseAgent() {
      this.lastNameSearch = "";
      this.theAgentId = "";
      this.hideAgentDetails = true;
      this.theAnswers = [];
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.getActiveQuestion - 1);
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px lightgray;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
}

.dropdown-item:hover {
  background: #edf2f7;
}
</style>
