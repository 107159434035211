import { translateTexts } from "../translate-service-new"; // Import your translation function
import { mapGetters } from "vuex";

export const employmentTranslationMixin = {
  computed: {
    ...mapGetters(["getAppLanguage"]),
  },
  methods: {
    translateTexts, // Assuming you already have this method to handle translation

    async translateEmploymentQuestions(resp, changeLanguage = false) {
      // Ensure resp is valid
      if (
        resp &&
        (resp?.employmentInfoData ||
          (Array.isArray(resp) && resp.some((item) => item?.employmentInfoData)))
      ) {
        try {
          const targetLanguage = this.getAppLanguage; // Define target language based on app's language setting
          const fieldsToTranslate = ["questionAnswer", "questionText"]; // Fields to translate

          // Arrays to collect questionAnswer and questionText for batch translation
          const questionsToTranslateAnswers = [];
          const questionsToTranslateTexts = [];
          const questionMapping = []; // To store reference for questionAnswer
          const questionTextMapping = []; // To store reference for questionText
          // Scenario 1: Handle employmentInfoData case
          if (resp?.employmentInfoData) {
            for (const employmentInfo of resp?.employmentInfoData) {
              for (const employmentInfoDetail of employmentInfo?.employmentInfoDetails) {
                for (const employmentInfoData of employmentInfoDetail?.employmentInfoData) {
                  // Push questionAnswer to questionsToTranslateAnswers
                  if (employmentInfoData?.questionAnswer) {
                    questionsToTranslateAnswers.push(
                      employmentInfoData?.questionAnswer
                    );
                    questionMapping.push(employmentInfoData); // Store reference for questionAnswer
                  }
                  // Push questionText to questionsToTranslateTexts
                  if (employmentInfoData?.questionText) {
                    questionsToTranslateTexts.push(
                      employmentInfoData.questionText
                    );
                    questionTextMapping.push(employmentInfoData); // Store reference for questionText
                  }
                }
              }
            }
          }
          // Scenario 2: Handle employmentInfoDetails case
          if (Array.isArray(resp)) {
            for (const each of resp) {
              if (each?.employmentInfoData) {
                for (const employmentInfoData of each?.employmentInfoData) {
                    // Push questionAnswer to questionsToTranslateAnswers
                    if (employmentInfoData?.questionAnswer) {
                      questionsToTranslateAnswers.push(
                        employmentInfoData?.questionAnswer
                      );
                      questionMapping.push(employmentInfoData); // Store reference for questionAnswer
                    }
                    // Push questionText to questionsToTranslateTexts
                    if (employmentInfoData?.questionText) {
                      questionsToTranslateTexts.push(
                        employmentInfoData?.questionText
                      );
                      questionTextMapping.push(employmentInfoData); // Store reference for questionText
                    }
                }
              }
            }
          }
          // Perform batch translation for both questionAnswer and questionText fields
          let translatedAnswers = [];

          if (questionsToTranslateAnswers.length > 0) {
            const translatedAnswerBatch = await this.translateTexts(
              questionsToTranslateAnswers,
              fieldsToTranslate,
              targetLanguage
            );
            translatedAnswers = [
              ...translatedAnswers,
              ...translatedAnswerBatch,
            ]; // Append to the main array
          }

          if (questionsToTranslateTexts.length > 0) {
            const translatedTextBatch = await this.translateTexts(
              questionsToTranslateTexts,
              fieldsToTranslate,
              targetLanguage
            );
            translatedAnswers = [...translatedAnswers, ...translatedTextBatch]; // Append to the main array
          }

          // Map the translated answers back to the original questions
          translatedAnswers.forEach((translatedAnswer, index) => {
            // Handle questionAnswer translation
            if (index < questionMapping.length) {
              const question = questionMapping[index];
              if (
                question &&
                (!question.translatedQuestionAnswer || changeLanguage)
              ) {
                question.translatedQuestionAnswer = translatedAnswer;
              }
            }
            // Handle questionText translation
            else if (
              index - questionMapping.length <
              questionTextMapping.length
            ) {
              const questionText =
                questionTextMapping[index - questionMapping.length];
              if (
                questionText &&
                (!questionText.translatedQuestionText || changeLanguage)
              ) {
                questionText.translatedQuestionText = translatedAnswer;
              }
            }
          });
          // Return the modified response after translation
          return resp;
        } catch (error) {
          console.error("Error in translation process: ", error);
          return resp; // Return the original response if an error occurs
        }
      } else {
        console.warn(
          "Invalid response structure. Neither employmentInfoData nor employmentInfoDetails found."
        );
        // Return the original response without modifications
        return resp;
      }
    },
  },
};
