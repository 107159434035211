<template>
  <div>
    <div class="question">
      <!-- Question Text -->
      <h2>{{ questionTitle }}</h2>

      <!-- Question Description -->
      <p class="mt-3" v-show="question.questionDescription">
        {{ questionDesc }}
      </p>

      <!-- Question Sub Group Name -->
      <p class="personal-info mt-3" v-show="question.subGroupName">
        <span>{{ questionSubGName }}</span>
      </p>
    </div>
    <div class="accordion" v-if="answerOptions.length">
      <ul>
        <li v-for="(option, index) in answerOptions" :key="index">
          <div class="title-amt">
            <div class="checkbox">
              <input type="checkbox" :id="`multiplecheckbox${index + 1}`" :true-value="option.optionValue"
                :false-value="undefined" v-model="mainAnswer[index]" @click="removeTheOption(option.optionValue)" />
              <label :for="`multiplecheckbox${index + 1}`" role="button" data-bs-toggle="collapse"
                :data-bs-target="`multiplecheckbox${index + 1}`" aria-expanded="true"
                :aria-controls="`multiplecheckbox${index + 1}`">{{ option.optionName }}</label>
            </div>
            <div v-if="areSubQuestionsAvailable(option.optionValue, true)" class="amt">
              <span>${{
                getSubQuestionTotal(option.optionValue) | formatNumber
                }}</span>
            </div>
          </div>
          <div v-if="areSubQuestionsAvailable(option.optionValue, false)"
            class="accordion-body accordion-collapse collapse"
            :class="mainAnswer[index] === option.optionValue ? 'show' : ''" :style="mainAnswer[index] === option.optionValue ? setUnsetOverflow : ''
            " :id="`multiplecheckbox${index + 1}`">
            <div class="panel-body">
              <div v-for="(sub, i) in theSubQuestions" :key="i">
                <div v-if="
                  option.optionValue === sub.optionUndertheSubQuestionAdded &&
                    sub.subQuestionOf === question.questionId
                  " class="row align-items-center mb-2">
                  <div v-if="sub.questionType !== 'MultipleChoice'" class="col-lg-6 col-md-12 col-sm-6">
                    <label>{{ sub?.translatedQuestionText }}</label>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-6" :class="sub.questionType === 'MultipleChoice' ? 'w-100' : ''
                    ">
                    <div v-if="inputFieldsWithIcons.includes(sub.questionType)" class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" :id="'basic-addon' + i"><i
                            :class="getIcons(sub.questionType)"></i></span>
                      </div>
                      <input :type="getFieldType(sub)" :inputmode="getInputMode(sub)" class="form-control"
                        :id="sub.questionText" :placeholder="sub?.translatedQuestionText" v-model="subAnswers[i]" />
                      <div class="input-group-append" v-if="
                          getFieldType(sub) === 'password' ||
                          getFieldType(sub) === 'show-password'
                        ">
                        <span @click="showInput(i)" class="input-group-text input-group-eye bg-transparent"><i :class="
                              getFieldType(que) === 'show-password'
                                ? 'far fa-eye'
                                : 'fas fa-eye-slash'
                            "></i></span>
                      </div>
                    </div>

                    <div v-else-if="
                      sub.questionType === 'MultipleChoice' &&
                      sub?.questionOptions?.length
                    ">
                      <div class="row">
                        <p class="personal-info">
                          {{ sub?.translatedQuestionText }}
                          <small v-if="sub.isMandatory" class="asterisk-mark">*</small>
                        </p>
                      </div>
                      <div class="row">
                        <div class="inner-accordion">
                          <div v-for="(subChecks, index) in sub?.questionOptions" :key="index" class="checkbox">
                            <input type="checkbox" :id="subChecks.optionName" :value="subChecks.optionValue"
                              v-model="childMultipleSelect" @change="addOptions(i)" />
                            <label :for="subChecks.optionName">{{
                              subChecks?.translatedOptionName
                              }}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <multiselect v-else-if="
                        sub.questionType === 'Text' &&
                        sub.questionOptions &&
                        sub.questionOptions.length
                      " :searchable="false" :options="getTheNames(sub.questionOptions, i)" v-model.trim="subAnswers[i]"
                      :multiple="true">
                    </multiselect>

                    <input v-else :type="getFieldType(sub)" :inputmode="getInputMode(sub)" class="form-control"
                      :id="sub.questionText" :placeholder="sub?.translatedQuestionText" v-model.trim="subAnswers[i]"
                      @input="
                        sub.questionType === 'Email'
                          ? checkEmail(subAnswers[i])
                          : ''
                      " />
                    <small class="text-danger" v-if="sub.questionType === 'Email' && !validEmail">
                      {{ translatedData?.emailValidation }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="mt-3 final-total amt">
        <span>{{ translatedData?.total }}: ${{ getFinalTotal() | formatNumber }}</span>
      </div>
    </div>

    <div class="button mt-4 justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>{{ translatedData?.previous }}</span>
        </button>
        <button type="button" class="btn" :class="checkTheValidation ? 'btn-red' : ''" :disabled="!checkTheValidation"
          @click="saveTheData">
          <span>{{ translatedData?.continue }}</span>
        </button>
      </div>
      <div class="align-text-right col-sm-6">
        <a v-if="question.questionLink" :data-bs-toggle="question.questionLink && question.questionLinkDescription
              ? 'modal'
              : ''
          " data-bs-target="#infoModal" class="text-underline">{{ question.questionLink }}</a>
      </div>
      <info-modal v-if="question.questionLink && question.questionLinkDescription" :title="translatedData?.questionLink"
        :description="translatedData.questionLinkDescription" :close="translatedData?.close" />
    </div>
  </div>
</template>

<script>
import commonFunctions from "./../../mixins/commonfunctions";
import { translationMixin } from "../../mixins/translationMixin";
import { translateTexts } from "./../../translate-service-new";
import InfoModal from "../InfoModal.vue";
import Multiselect from "vue-multiselect";
import cloneDeep from "lodash/cloneDeep";
import { translateQuestionsAndOptions } from "../../utilis/translationUtil";

export default {
  name: "MultipleChoice",
  mixins: [commonFunctions, translationMixin],
  components: { InfoModal, Multiselect },
  data() {
    return {
      mainAnswer: [],
      subAnswers: [],
      childMultipleSelect: [],
      repeatScheduleOptions: false,
      theSubQuestions: [],
      theAPIAnswer: [],
      apiName: "",
      lastOption: "",
      setUnsetOverflow: "",
      data: {
        emailValidation: "Please enter a valid email address.",
        total: "Total",
        previous: "Previous",
        continue: "Continue",
        close: "Close",
        questionLink: this.question?.questionLink,
        questionLinkDescription: this.question?.questionLinkDescription
      }
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  watch: {
    question: {
      deep: true,
      immediate: true,
      async handler() {
        this.extractSubQuestions();
        this.prePopulateAnswers();
      },
    },

    getSubQuestions: {
      deep: true,
      immediate: true,
      async handler() {
        this.extractSubQuestions();
        this.prePopulateAnswers();
        // Fetch and add translated texts
      },
    },
    getAppLanguage: {
      immediate: true,
      deep: true,
      async handler() {
        await this.fetchTranslatedSubQuestions()
      },
    },
  },
  computed: {
    checkTheValidation() {
      let checkTheSubs = [];

      this.mainAnswer.forEach((main) => {
        if (main) {
          let theIndexes = this.theSubQuestions.reduce(
            (acc, el, index) =>
              el.questionType !== "MultipleChoice" &&
                el.optionUndertheSubQuestionAdded === main
                ? [...acc, index]
                : acc,
            []
          );

          if (theIndexes.length) {
            checkTheSubs.push(
              theIndexes.some(
                (index) =>
                  this.subAnswers[index] !== "" &&
                  this.subAnswers[index] !== undefined &&
                  this.subAnswers[index].length
              )
            );
          }
        }
      });

      return (
        checkTheSubs.every((z) => z === true) &&
        this.mainAnswer.some((y) => y !== undefined) &&
        this.validEmail &&
        !this.repeatScheduleOptions
      );
    },

    isSelfEmploymentSelected() {
      if (this.mainAnswer.includes("Self-employment"))
        return this.childMultipleSelect.length;

      return true;
    },

    doesLastElHaveSubQues() {
      let queOptions = this.question.questionOptions;
      let lastElement = queOptions.at(-1).optionValue;

      return this.getSubQuestions.some(
        (i) => i.optionUndertheSubQuestionAdded === lastElement
      );
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  mounted() {
    this.checkTheDependancy(this.question);
    this.apiName = this.question.apiName;

    this.extractSubQuestions();
    this.prePopulateAnswers();
  },
  methods: {
    translateTexts,
    addOptions(index) {
      if (
        this.childMultipleSelect.filter((x) => x.includes("Schedule K-1"))
          .length > 1
      ) {
        this.repeatScheduleOptions = true;
        this.err("Err! Please select only one 'Schedule K-1' option");
      } else {
        this.repeatScheduleOptions = false;
      }

      this.subAnswers[index] = this.childMultipleSelect.join(";");
    },

    addTheCSSProperty() {
      this.setUnsetOverflow = "";

      setTimeout(() => {
        this.setUnsetOverflow = "overflow: unset !important;";
      }, 500);
    },

    getTheNames(options, index) {
      let theNames = [];

      for (let i = 0; i < options.length; i++) {
        let value = options[i].optionValue;
        let theId = value.slice(1, -1);
        let theQue = this.getQuestions.find((v) => v.questionId === theId);
        if (theQue?.questionAnswer) theNames.push(theQue.questionAnswer);
      }

      if (theNames.length === 1) this.subAnswers[index] = theNames;

      return theNames;
    },

    showInput(index) {
      let queType = this.theSubQuestions[index].questionType;
      this.theSubQuestions[index].questionType =
        queType === "Password" ? "Show-Password" : "Password";
    },

    async extractSubQuestions() {
      this.theSubQuestions = this.getSubQuestions.filter(
        (v) => v.subQuestionOf === this.question.questionId
      );
      await this.fetchTranslatedSubQuestions();
    },
    async fetchTranslatedSubQuestions() {
      const targetLanguage = this.getAppLanguage
      this.theSubQuestions = await translateQuestionsAndOptions(
        this.theSubQuestions,
        this.translateTexts,
        targetLanguage
      );
    },

    prePopulateAnswers() {
      if (this.question.questionAnswer && this.question.questionAnswer.length) {
        this.mainAnswer = this.question.questionOptions.map((w) => {
          let check = this.question.questionAnswer.find(
            (l) => w.optionValue === l
          );

          if (check) {
            this.setUnsetOverflow = "overflow: unset !important;";
            return check;
          } else {
            return undefined;
          }
        });
      }

      if (this.theSubQuestions.length) {
        this.theSubQuestions.forEach((v, index) => {
          if (
            v.questionType === "Text" &&
            v.questionOptions &&
            v.questionOptions.length
          ) {
            this.subAnswers[index] = v.questionAnswer
              ? v.questionAnswer.indexOf("&") > -1
                ? v.questionAnswer.split(" & ")
                : v.questionAnswer
              : undefined;
          } else if (
            v.questionType === "MultipleChoice" &&
            v.questionOptions &&
            v.questionOptions.length
          ) {
            this.childMultipleSelect = v.questionAnswer
              ? v.questionAnswer.split(";")
              : [];
            this.subAnswers[index] = v.questionAnswer || undefined;
          } else {
            this.subAnswers[index] = v.questionAnswer || undefined;
          }
        });
      }
    },

    getAnswerId(option) {
      let answer_id = "";
      let mainQues = this.getAllMcQuestions.mainQuestions;

      if (mainQues && mainQues.length) {
        mainQues.forEach((v) => {
          if (
            v.mainQuestion.questionAnswer === option &&
            v.mainQuestion.questionId === this.question.questionId
          ) {
            answer_id = v.recordId;
          }
        });
      }

      if (answer_id) return answer_id;
      else return null;
    },

    async saveTheData() {
      if (!this.isSelfEmploymentSelected) {
        this.err(
          "To proceed with 'Self-employment' option, at least one option must be selected for the income reported on tax return"
        );
        return;
      }

      if (!this.doesLastElHaveSubQues) {
        let queOptions = this.question.questionOptions;
        this.lastOption = queOptions.at(-1).optionValue;

        let checkIfAdded = this.mainAnswer.find((v) => v === this.lastOption);
        let getSelectedOptions = this.mainAnswer.filter((v) => v !== undefined);

        if (!checkIfAdded && this.getFinalTotal() <= 0) {
          this.warning("Please enter income greater than $0 to proceed");
          return;
        }

        if (
          checkIfAdded &&
          (this.getFinalTotal() > 0 ||
            (getSelectedOptions.length > 1 && this.getFinalTotal() <= 0))
        ) {
          this.warning("Please select valid options to proceed.");
          return;
        }
      } else {
        if (this.getFinalTotal() <= 0) {
          this.warning(
            "Please enter income/assets amount greater than $0 to proceed"
          );
          return;
        }
      }

      this.fetchCompLoaderStatus(true);

      // payload for local store - Starts
      let localPayLoad = {
        question_id: this.question.questionId,
        main_answer: this.mainAnswer.filter((v) => v !== undefined),
        sub_questions: this.theSubQuestions,
        sub_answers: this.subAnswers,
      };

      this.addMainAndSubAnswersLocally(localPayLoad); //Vuex Store
      // payload for local store - Ends

      if (this.getUserId) {
        // payload for API and backend - Starts
        let main = this.mainAnswer;
        let subs = this.theSubQuestions;
        let mainArr = [];

        for (let i = 0; i < main.length; i++) {
          let que = cloneDeep(this.question);

          if (main[i]) {
            let obj = {
              answerId: this.getAnswerId(main[i]),
              answerDetails: [],
            };

            que.questionAnswer = main[i];
            obj.answerDetails.push(que);

            for (let k = 0; k < subs.length; k++) {
              if (subs[k].optionUndertheSubQuestionAdded === main[i]) {
                if (
                  subs[k].questionType === "Text" &&
                  subs[k].questionOptions &&
                  subs[k].questionOptions.length
                ) {
                  let answer;

                  if (this.subAnswers[k]?.length) {
                    answer = this.subAnswers[k].toString();
                    answer = answer.replaceAll(",", " & ");
                    subs[k].questionAnswer = answer;
                  } else {
                    subs[k].questionAnswer = null;
                  }
                } else {
                  subs[k].questionAnswer = this.subAnswers[k] || 0;
                }
                obj.answerDetails.push(subs[k]);
              }
            }

            mainArr.push(obj);
          }
        }

        let payLoad = {
          updateAnswer: mainArr,
          prospectId: this.getUserId,
          flowName: this.getAppFlow,
          orgId: this.getOrgId,
        };
        // payload for API and backend - Ends

        await this.$http
          .post(
            "/multipleChoice/updateDetails?apiName=" + this.apiName,
            payLoad
          )
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              await this.getMultipleChoiceData();
              this.nextQuestion();
            }

            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      if (!this.getUserId) this.nextQuestion();

      this.fetchCompLoaderStatus(false);
    },

    nextQuestion() {
      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      )
        this.$router.push("/submit-application");

      if (this.getActiveQuestion <= this.getQuestions.length)
        this.fetchActiveQuestion(this.getActiveQuestion + 1);
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.getActiveQuestion - 1);
    },

    async removeTheOption(option) {
      this.addTheCSSProperty();

      if (!this.mainAnswer.includes(option)) return;

      if (this.mainAnswer.includes(option)) {
        this.fetchCompLoaderStatus(true);

        for (let i = 0; i < this.theSubQuestions.length; i++) {
          if (this.theSubQuestions[i].optionUndertheSubQuestionAdded === option)
            this.subAnswers[i] = "";
        }

        let answerId = this.getAnswerId(option);

        if (answerId) {
          await this.$http
            .get(
              "/multipleChoice/deleteDetails?answerId=" +
              answerId +
              "&apiName=" +
              this.apiName +
              "&flowName=" +
              this.getAppFlow +
              "&prospectId=" +
              this.getUserId +
              "&sectionName=" +
              this.getActiveSection +
              "&orgId=" +
              this.getOrgId
            )
            .then(async (response) => {
              let resp = response.data;

              if (resp.response === "Success") {
                await this.getMultipleChoiceData();
                this.prePopulateAnswers();
              }

              if (resp.response === "Error") {
                this.err(resp.message);
              }
            })
            .catch((error) => {
              this.err(error);
              console.log(error);
            });
        }

        this.fetchCompLoaderStatus(false);
      }
    },

    getFinalTotal() {
      let finalTotal = 0;

      for (let i = 0; i < this.theSubQuestions.length; i++) {
        if (this.theSubQuestions[i].questionType === "Currency") {
          if (this.theSubQuestions[i].isMonthlyCurrency) {
            finalTotal += Number(this.subAnswers[i] * 12 || 0);
          } else {
            finalTotal += Number(this.subAnswers[i] || 0);
          }
        }
      }

      return finalTotal;
    },

    getSubQuestionTotal(option) {
      let total = 0;

      for (let i = 0; i < this.theSubQuestions.length; i++) {
        if (
          this.theSubQuestions[i].optionUndertheSubQuestionAdded === option &&
          this.theSubQuestions[i].questionType === "Currency"
        ) {
          if (this.theSubQuestions[i].isMonthlyCurrency) {
            total += Number(this.subAnswers[i] * 12 || 0);
          } else {
            total += Number(this.subAnswers[i] || 0);
          }
        }
      }

      return total;
    },

    areSubQuestionsAvailable(option, checkCurrencyField) {
      if (checkCurrencyField)
        return this.theSubQuestions.some(
          (i) =>
            i.optionUndertheSubQuestionAdded === option &&
            i.questionType === "Currency" &&
            this.mainAnswer.includes(option)
        );
      else
        return this.theSubQuestions.some(
          (i) => i.optionUndertheSubQuestionAdded === option
        );
    },

    replaceCharacter(string) {
      let newString;
      newString = string.replaceAll(/[,' ']/g, "");
      return newString.toLowerCase();
    },

    async getMultipleChoiceData() {
      await this.$http
        .get(
          "/multipleChoice/getMultipleChoiceQuestionData?prospectId=" +
          this.getUserId +
          "&flowName=" +
          this.getAppFlow +
          "&orgId=" +
          this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            let mainQues = resp.mainQuestions;
            let subQues = resp.subQuestions;

            this.getQuestions.forEach((c) => {
              let findQue = mainQues.find(
                (x) => x.mainQuestion.questionId === c.questionId
              );

              if (findQue) {
                c.questionAnswer = findQue.options;
              } else {
                if (c.questionType === "MultipleChoice") c.questionAnswer = "";
              }
            });

            this.fetchQuestions(this.getQuestions);

            this.getSubQuestions.forEach((y) => {
              let findSubQue = subQues.find(
                (z) => z.questionId === y.questionId
              );

              if (findSubQue) {
                y = findSubQue;
              } else {
                y.questionAnswer = "";
              }
            });

            this.fetchSubQuestions(this.getSubQuestions);

            let payLoad = {
              mainQuestions: mainQues,
              subQuestions: subQues,
            };

            this.fetchAllMcQuestions(payLoad || {});
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
@import "../../assets/css/vue-multi-select.css";

.multiselect__tag {
  background: var(--primary-color) !important;
}
</style>
