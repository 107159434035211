<template>
  <div
    class="modal fade privacyPolicyModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>{{ translatedData?.title }}</h2>
          </div>
          <div class="content-desc">
            <p>
              <strong>{{ translatedData?.updated_effective_date }}</strong>
            </p>
            <p>
              {{ translatedData?.privacy_policy_description }}
            </p>
            <p>
              {{ translatedData?.review_policy_note }}
            </p>
            <p>
              <a name="_Toc43801899"></a>
              {{ translatedData?.privacy_joint_applicants_title }}
            </p>
            <p>
              {{ translatedData?.privacy_joint_applicants_info }}
            </p>
            <p>
              <a name="_Toc43801900"></a>
              {{ translatedData?.candid_collect_info_title }}
            </p>
            <p>
              <a name="_Toc43801901"></a>
              <strong>{{
                translatedData?.categories_of_information_title
              }}</strong>
            </p>
            <p>
              {{ translatedData?.categories_of_information_content }}
            </p>
            <p>
              {{ translatedData?.identifiers }}
            </p>
            <p>
              {{ translatedData?.commercial_information }}
            </p>
            <p>{{ translatedData?.financial_information }}</p>
            <p>
              {{ translatedData?.internet_device_activity }}
            </p>
            <p>
              {{ translatedData?.geolocation_information }}
            </p>
            <p>
              {{ translatedData?.professional_employment_data }}
            </p>
            <p>
              {{ translatedData?.education_data }}
            </p>
            <p>
              {{ translatedData?.protected_classification_data }}
            </p>
            <p>{{ translatedData?.insurance_information }}</p>
            <p>{{ translatedData?.physical_characteristics }}</p>
            <p>
              {{ translatedData?.inference_data }}
            </p>
            <p>
              {{ translatedData?.other_information }}
            </p>
            <p>
              <a name="_Toc43801902"></a>
              <strong>{{ translatedData?.categories_of_sources_title }}</strong>
            </p>
            <p>
              {{ translatedData?.categories_of_sources_content }}
            </p>
            <p>
              <a name="_Toc43801903"></a>
              <strong>{{
                translatedData?.information_provided_directly_title
              }}</strong>
            </p>
            <p>
              {{ translatedData?.information_provided_directly_content }}
            </p>
            <p>{{ translatedData?.register_account }}</p>
            <p>{{ translatedData?.enter_information }}</p>
            <p>{{ translatedData?.communicate_with_us }}</p>
            <p>
              {{ translatedData?.connect_third_party }}
            </p>
            <p>
              {{ translatedData?.example_registration_process }}
            </p>
            <p>
              <a name="_Toc43801904"></a>
              <a name="_Toc37019472"></a>
              <a name="_Toc37019553"></a>
              <a name="_Toc37019707"></a>
              <strong
                >{{ translatedData?.information_collected_passively_title }}
              </strong>
            </p>
            <p>
              <strong
                >{{ translatedData?.device_usage_information_title }}
              </strong>
              {{ translatedData?.device_usage_information_content }}
            </p>
            <p>
              <strong>{{ translatedData?.cookies_technologies_title }} </strong>
              {{ translatedData?.cookies_technologies_content }}
            </p>
            <p>
              <strong>{{ translatedData?.blocking_cookies_title }} </strong>
              {{ translatedData?.blocking_cookies_content }}
              <u>
                <a
                  href="http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies"
                  target="_blank"
                >
                  {{ translatedData?.connect_third_party_ie }}
                </a>
              </u>
              ;
              <u>
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  target="_blank"
                >
                  {{ translatedData?.connect_third_party_chrome }}
                </a>
              </u>
              ;
              <u>
                <a
                  href="http://kb.mozillazine.org/Cookies#Firefox"
                  target="_blank"
                >
                  {{ translatedData?.connect_third_party_firefox }}
                </a>
              </u>
              ; or
              <u>
                <a href="http://support.apple.com/kb/PH5042" target="_blank">
                  {{ translatedData?.connect_third_party_safari }}
                </a>
              </u>
              {{ translatedData?.connect_third_party_end }}
            </p>
            <p>
              <a name="_Toc43801905"></a>
              <strong
                >{{ translatedData?.information_from_third_parties_title }}
              </strong>
            </p>
            <p>
              <a name="_Toc37019476"></a>
              <a name="_Toc37019557"></a>
              <a name="_Toc37019710"></a>
              {{ translatedData?.information_from_third_parties_content }}
            </p>
            <p>
              <a name="_Toc43801906"></a>
              {{ translatedData?.how_candid_uses_information_title }}
            </p>
            <p>
              {{ translatedData?.how_candid_uses_information_content }}
            </p>
            <p>
              {{ translatedData?.performing_services }}
            </p>
            <p>
              {{ translatedData?.processing_transaction }}
            </p>
            <p>{{ translatedData?.facilitating_registration }}</p>
            <p>
              {{ translatedData?.sending_activity_information }}
            </p>
            <p>
              {{ translatedData?.detecting_security_incidents }}
            </p>
            <p>
              {{ translatedData?.debugging_platform }}
            </p>
            <p>
              {{ translatedData?.short_term_use }}
            </p>
            <p>{{ translatedData?.undertaking_research_reporting }}</p>
            <p>
              {{ translatedData?.technological_development }}
            </p>
            <p>
              {{ translatedData?.contacting_users }}
            </p>
            <p>
              {{ translatedData?.processing_inquiries }}
            </p>
            <p>
              {{ translatedData?.personalizing_content }}
            </p>
            <p>
              {{ translatedData?.enforcing_legal_terms }}
            </p>
            <p>
              {{ translatedData?.aggregated_deidentified_information }}
            </p>
            <p>
              <a name="_Toc43801907"></a>
              {{ translatedData?.personalizing_content_title }}
            </p>
            <p>
              <strong>{{
                translatedData?.online_email_analytics_title
              }}</strong>
              {{ translatedData?.online_email_analytics_content }}
              <u>
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                >
                  {{ translatedData?.online_email_analytics_content_1 }}
                </a>
              </u>
              {{ translatedData?.online_email_analytics_content_2 }}
            </p>
            <p>
              <strong>{{ translatedData?.do_not_track_notice_title }} </strong>
              {{ translatedData?.do_not_track_notice_content }}
              <u
                ><a href="http://allaboutdnt.com/" target="_blank"
                  >{{ translatedData?.do_not_track_notice_content_1 }}
                </a></u
              >.
            </p>
            <p>
              <a name="_Toc43801908"></a>
              {{ translatedData?.how_candid_shares_information_title }}
            </p>
            <p>
              {{ translatedData?.information_sharing_notice }}
            </p>
            <p>
              <u>{{ translatedData?.financial_services_providers_title }} </u>
              {{ translatedData?.financial_services_providers_content }}
            </p>
            <p>
              <u>{{ translatedData?.affiliates_subsidiaries_title }} </u>
              {{ translatedData?.affiliates_subsidiaries_content }}
            </p>
            <p>
              <u>{{ translatedData?.service_providers_title }} </u>
              {{ translatedData?.service_providers_content }}
            </p>
            <p>
              <a name="_30j0zll"></a>
              <u>{{ translatedData?.data_access_providers_title }}</u>
              {{ translatedData?.data_access_providers_content }}
              <u>
                <a
                  href="https://plaid.com/legal/#Privacy-Policy"
                  target="_blank"
                >
                  {{ translatedData?.data_access_providers_content_1 }}
                </a>
              </u>
              ,
              <u>
                <a
                  href="https://www.finicity.com/privacy-policy/"
                  target="_blank"
                >
                  {{ translatedData?.data_access_providers_content_2 }}
                </a>
              </u>
              {{ translatedData?.data_access_providers_content_3 }}
              <u>
                <a
                  href="https://www.yodlee.com/legal/privacy-notice/"
                  target="_blank"
                >
                  {{ translatedData?.data_access_providers_content_4 }}
                </a>
              </u>
              {{ translatedData?.data_access_providers_content_5 }}
            </p>
            <p>
              <u>{{ translatedData?.consent_title }}</u>
              {{ translatedData?.consent_content }}
            </p>
            <p>
              <u>{{ translatedData?.protection_of_candid_title }}</u>
              {{ translatedData?.protection_of_candid_content }}
            </p>
            <p>
              <u>{{ translatedData?.business_transfers_title }}</u>
              {{ translatedData?.business_transfers_content }}
            </p>
            <p>
              <a name="_Toc43801909"></a>
              {{ translatedData?.data_security_title }}
            </p>
            <p>
              {{ translatedData?.data_security_content }}
            </p>
            <p>
              {{ translatedData?.password_recommendation_content }}
              <a href="mailto:info@candid.inc">info@candid.inc</a>
              {{ translatedData?.password_recommendation_content }}
            </p>
            <p>
              <a name="_Toc43801910"></a>
              {{ translatedData?.choices_title }}
            </p>
            <p>
              <u>{{ translatedData?.updating_deleting_info_title }}</u>
              {{ translatedData?.updating_deleting_info_content }}
            </p>
            <p>
              <u>{{ translatedData?.account_access_title }}</u>
              {{ translatedData?.account_access_content }}
            </p>
            <p>
              <a name="_Toc43801911"></a>
              {{ translatedData?.california_residents_title }}
            </p>
            <p>
              {{ translatedData?.california_policy_title }}
            </p>
            <p>
              <a name="_Toc43801912"></a>
              <a name="_Notice_at_Collection"></a>
              <strong>{{ translatedData?.right_to_know_title }}</strong>
            </p>
            <p>
              {{ translatedData?.right_to_know_content }}
            </p>
            <p>
              <a name="_Toc43801913"></a>
              <strong>{{ translatedData?.right_to_delete_title }}</strong>
            </p>
            <p>
              {{ translatedData?.right_to_delete_content }}
            </p>
            <p>
              <a name="_Toc43801914"></a>
              <a name="_Toc43801915"></a>
              {{ translatedData?.authorized_agents_title }}
            </p>
            <p>
              {{ translatedData?.authorized_agents_content }}
            </p>
            <p>{{ translatedData?.authorized_agents_content_1 }}</p>
            <p>{{ translatedData?.authorized_agents_content_2 }}</p>
            <p>
              {{ translatedData?.authorized_agents_content_3 }}
            </p>
            <p>
              {{ translatedData?.authorized_agents_content_4 }}
            </p>
            <p>
              {{ translatedData?.authorized_agents_content_5 }}
            </p>
            <p>
              <a name="_Toc43801916"></a>
              {{ translatedData?.additional_rights_title }}
            </p>
            <p>
              {{ translatedData?.additional_rights_content }}
              for such third parties.
              <strong>
                {{ translatedData?.additional_rights_content_1 }}
              </strong>
            </p>
            <p>
              <a name="_Toc43801917"></a>
              {{ translatedData?.financial_incentives_title }}
            </p>
            <p>
              {{ translatedData?.financial_incentives_content }}
            </p>
            <p>
              <a name="_Toc43801918"></a>
              {{ translatedData?.non_discrimination_rights_title }}
            </p>
            <p>
              {{ translatedData?.non_discrimination_rights_content }}
            </p>
            <p>{{ translatedData?.non_discrimination_rights_content_1 }}</p>
            <p>
              {{ translatedData?.non_discrimination_rights_content_2 }}
            </p>
            <p>
              {{ translatedData?.non_discrimination_rights_content_3 }}
            </p>
            <p>
              {{ translatedData?.non_discrimination_rights_content_4 }}
            </p>
            <p>
              <a name="_Toc43801919"></a>
              {{ translatedData?.privacy_laws_rights_title }}
            </p>
            <p>
              {{ translatedData?.privacy_laws_rights_content }}
            </p>
            <p>
              <a name="_Toc43801920"></a>
              {{ translatedData?.technology_service_provider_title }}
            </p>
            <p>
              {{ translatedData?.technology_service_provider_content }}
            </p>
            <p>
              <a name="_Toc43801921"></a>
              {{ translatedData?.contact_us_title }}
            </p>
            <p>
              {{ translatedData?.contact_us_content }}
            </p>
            <p>
              <a name="_Toc43801922"></a>
              {{ translatedData?.nevada_residents_title }}
            </p>
            <p>
              {{ translatedData?.nevada_residents_content }}
            </p>
            <p>
              <a name="_Toc43801923"></a>
              {{ translatedData?.third_party_links_title }}
            </p>
            <p>
              {{ translatedData?.third_party_links_content }}
            </p>
            <p>
              <a name="_Toc43801924"></a>
              {{ translatedData?.children_policy_title }}
            </p>
            <p>
              {{ translatedData?.children_policy_content }}
            </p>
            <p>
              <a name="_Toc43801925"></a>
              {{ translatedData?.retention_of_information_title }}
            </p>
            <p>
              {{ translatedData?.retention_of_information_content }}
            </p>
            <p>
              <a name="_Toc43801926"></a>
              {{ translatedData?.policy_changes_title }}
            </p>
            <p>
              {{ translatedData?.policy_changes_content }}
            </p>
            <p>
              <a name="_Toc43801927"></a>
              {{ translatedData?.questions_about_policy_title }}
            </p>
            <p>
              {{ translatedData?.questions_about_policy_content }}
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>{{ translatedData?.close_button }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import privacyJson from "../../data/privacy";
import { translationMixin } from "../../mixins/translationMixin";
export default {
  name: "PrivacyPolicyModal",
  mixins: [translationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: privacyJson,
    };
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>
