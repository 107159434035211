<template>
  <div
    :id="id"
    class="modal fade termsOfUseModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>{{ translatedData?.modalTitle }}</h2>
          </div>
          <div class="content-desc">
            <p>
              <strong>{{ translatedData?.effectiveDate }}</strong>
            </p>
            <p>
              <strong>
                {{ translatedData?.importantNotice }}
              </strong>
            </p>
            <p>
              <strong>
                {{ translatedData?.platformNotice }}
              </strong>
            </p>
            <p>
              {{ translatedData?.welcomeMessage }}
            </p>
            <p>
              {{ translatedData?.termsAgreement }}
            </p>
            <p>
              {{ translatedData?.modificationsNotice }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.jointApplicantsTitle }}</strong></u
              >
              {{ translatedData?.jointApplicantsContent }}
            </p>
            <p>
              {{ translatedData?.contactInfo }}
              <span>info@candid.inc</span>
            </p>
            <ol>
              <li>
                <p>{{ translatedData?.eligibilityTitleScope }}</p>
              </li>
            </ol>
            <p>
              <strong>{{ translatedData?.eligibilityTitle }}</strong>
              {{ translatedData?.eligibilityContent }}
            </p>
            <p>
              <strong>{{ translatedData?.restrictionsTitle }}</strong>
              {{ translatedData?.restrictionsDescription }}
            </p>
            <ol start="2">
              <li>
                <p>{{ translatedData?.accountUseTitle }}</p>
              </li>
            </ol>
            <p>
              <strong>{{ translatedData?.accountRegistrationTitle }}</strong>
              {{ translatedData?.accountRegistrationContent }}
            </p>
            <p>
              <strong>{{ translatedData?.unauthorizedUseTitle }}</strong>
              {{ translatedData?.unauthorizedUseContentTag }}
              <a href="mailto:info@candid.inc">info@candid.inc</a>
              {{ translatedData?.unauthorizedUseContentTagContent }}
            </p>
            <ol start="3">
              <li>
                <p>{{ translatedData?.technicalRequirementsTitle }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.technicalRequirementsContent }}
            </p>
            <ol start="4">
              <li>
                <p>{{ translatedData?.proprietaryRightsTitle }}</p>
              </li>
            </ol>
            <p>
              <strong>{{ translatedData?.intellectualPropertyRightsTitle }}</strong>
              {{ translatedData?.intellectualPropertyRightsContent }}
            </p>
            <p>
              <strong>{{ translatedData?.useOfMarksTitle }}</strong>
              {{ translatedData?.useOfMarksContent }}
            </p>
            <ol start="5">
              <li>
                <p>{{ translatedData?.authorizationToUseTitle }}</p>
              </li>
            </ol>
            <p>
              <strong>{{ translatedData?.submittedInfoTitle }}</strong>
              {{ translatedData?.submittedInfoContent }}
            </p>
            <p>
              <strong>{{ translatedData?.accountInformationTitle }}</strong>
              {{ translatedData?.accountInformationContent }}
            </p>
            <p>
              {{ translatedData?.submittedInfoTransmission }}
            </p>
            <p>
              {{ translatedData?.limitedPowerOfAttorney }}
            </p>
            <p>
              <strong>
                {{ translatedData?.rightToProvideInfoTitle }}
              </strong>
              {{ translatedData?.rightToProvideInfoContent }}
            </p>
            <p>
              <strong>{{ translatedData?.feedbackTitle }}</strong>
              {{ translatedData?.feedbackContent }}
            </p>
            <ol start="6">
              <li>
                <p>{{ translatedData?.yourLicenseProhibitedContent }}</p>
              </li>
            </ol>
            <p>
              <strong>{{ translatedData?.yourLicenseTitle }}</strong>
              {{ translatedData?.yourLicenseContent }}
            </p>
            <p>
              <strong>{{ translatedData?.licenseGrantToCandidTitle }}</strong>
              {{ translatedData?.licenseGrantToCandidContent }}
            </p>
            <p>
              <strong>{{ translatedData?.prohibitedUsesTitle }}</strong>
              {{ translatedData?.prohibitedUsesContent }}
            </p>
            <ol></ol>
            <ul>
              <li>
                <p>
                  {{ translatedData?.harass_abuse_threaten }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.unlawful_harmful_info }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.commercial_use }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.copy_modify_distribute }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.automated_access }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.bypass_restrictions }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.advocates_illegal_activity }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.malicious_software }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.disable_impair_platform }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.distribute_ip_without_right }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.confidential_info }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.false_affiliation }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.frame_display_platform }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.violate_terms }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.facilitate_violations }}
                </p>
              </li>
              <li>
                <p>
                  {{ translatedData?.interfere_with_use }}
                </p>
              </li>
            </ul>
            <p>
              {{ translatedData?.right_to_remove_block }}
            </p>
            <ol start="7">
              <li>
                <p>{{ translatedData?.legal_compliance }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.candid_access_preserve_disclose }}
            </p>
            <p>
              {{ translatedData?.candid_no_professional_services }}
            </p>
            <ol start="8">
              <li>
                <p>{{ translatedData?.warranties_disclaimers }}</p>
              </li>
            </ol>
            <p>{{ translatedData?.use_at_own_risk }}</p>
            <p>
              {{ translatedData?.use_at_own_risk_content }}
            </p>
            <p>
              {{ translatedData?.download_at_own_risk }}
            </p>
            <p>
              {{ translatedData?.no_advice_creates_warranty }}
            </p>
            <p>
              {{ translatedData?.no_guarantees_results }}
            </p>
            <ol start="9">
              <li>
                <p>{{ translatedData?.limitation_of_liability }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.no_liability_for_indirect_damages }}
            </p>
            <p>
              {{ translatedData?.release_from_third_party_disputes }}
            </p>
            <ol start="10">
              <li>
                <p>{{ translatedData?.exclusions_limitations }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.jurisdictional_exclusions }}
            </p>
            <ol start="11">
              <li>
                <p>{{ translatedData?.indemnity }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.user_agrees_to_indemnify }}
            </p>
            <ol start="12">
              <li>
                <p>{{ translatedData?.thirdParty_links_platform }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.thirdParty_links_platform_content }}
            </p>
            <ol start="13">
              <li>
                <p>{{ translatedData?.modification_and_termination }}</p>
              </li>
            </ol>
            <p>
              <u
                ><strong>{{ translatedData?.modification_of_platform }}</strong></u
              >
              {{ translatedData?.modification_of_platform_content }}
            </p>
            <p>
              <strong>{{ translatedData?.termination }}</strong>
              <u>.</u>
              {{ translatedData?.termination_content }}
            </p>
            <ol start="14">
              <li>
                <p>
                  {{ translatedData?.mandatory_arbitration_and_class_action_waiver }}
                </p>
              </li>
            </ol>
            <p>
              <strong>
                {{ translatedData?.mandatory_arbitration_content }}
              </strong>
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.application }}</strong></u
              >
              {{ translatedData?.application_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.initial_dispute_resolution }}</strong></u
              >
              {{ translatedData?.initial_dispute_resolution_1 }}
              <a href="mailto:info@candid.inc">info@candid.inc</a
              >{{ translatedData?.initial_dispute_resolution_2 }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.binding_arbitration }}</strong></u
              >
              {{ translatedData?.binding_arbitration_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.arbitrators_powers }}</strong></u
              >
              {{ translatedData?.arbitrators_powers_content }}
            </p>
            <p>
              {{ translatedData?.arbitrators_powers_content1 }}
            </p>
            <p>
              {{ translatedData?.arbitrators_powers_content_2 }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.class_action_waiver }}</strong></u
              >
              {{ translatedData?.class_action_waiver_content }}
            </p>
            <p>
              <u>
                <strong>
                  {{
                    translatedData?.exception_litigation_of_intellectual_property_and_small_claims_court_claims
                  }}
                </strong>
              </u>
              {{ translatedData?.exception_litigation_content }}
            </p>
            <p>
              <u
                ><strong>{{
                  translatedData?.thirty_day_right_to_opt_out
                }}</strong></u
              >
              {{ translatedData?.thirty_day_right_to_opt_out_content_1 }}
              <a href="mailto:info@candid.inc">info@candid.inc</a>
              {{ translatedData?.thirty_day_right_to_opt_out_content_2 }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.changes_to_this_section }}</strong></u
              >
              {{ translatedData?.changes_to_this_section_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.survival }}</strong></u
              >
              {{ translatedData?.survival_content }}
            </p>
            <ol start="15">
              <li>
                <p>{{ translatedData?.controlling_law_and_severability }}</p>
              </li>
            </ol>
            <p>
              {{ translatedData?.controlling_law_and_severability_content }}
            </p>
            <ol start="16">
              <li>
                <p>{{ translatedData?.general_terms }}</p>
              </li>
            </ol>
            <p>
              <u
                ><strong>{{ translatedData?.force_majeure }}</strong></u
              >
              {{ translatedData?.force_majeure_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.no_waiver_severability }}</strong></u
              >
              {{ translatedData?.no_waiver_severability_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.third_party_beneficiaries }}</strong></u
              >
              {{ translatedData?.third_party_beneficiaries_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.statute_of_limitations }}</strong></u
              >
              {{ translatedData?.statute_of_limitations_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.miscellaneous }}</strong></u
              >
              {{ translatedData?.miscellaneous_content }}
            </p>
            <p>
              <u
                ><strong>{{ translatedData?.notices }}</strong></u
              >
              {{ translatedData?.notices_content }}
              <a href="mailto:info@candid.inc">info@candid.inc</a>.
            </p>
            <ol start="17">
              <li></li>
            </ol>
            <p>
              {{ translatedData?.questions_content }}
              <a href="mailto:info@candid.inc">info@candid.inc</a>.
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            > 
              <span>{{ translatedData?.close }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import termsJson from "../../data/termsJson";
import { translationMixin } from "../../mixins/translationMixin";
export default {
  name: "TermsOfUseModal",
  mixins: [translationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: termsJson,
    };
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>