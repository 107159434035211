// translationUtil.js
export const translateQuestionsAndOptions = async (
  questions,
  translateTexts,
  targetLanguage
) => {
  const questionTextsToTranslate = [];
  const questionMapping = [];
  const optionNamesToTranslate = [];
  const optionMapping = [];

  questions.forEach((question) => {
    if (question.questionText) {
      questionTextsToTranslate.push(question.questionText);
      questionMapping.push(question);
    }
    if (question.questionOptions && Array.isArray(question.questionOptions)) {
      question.questionOptions.forEach((option) => {
        if (option.optionName) {
          optionNamesToTranslate.push(option.optionName);
          optionMapping.push(option);
        }
      });
    }
  });

  if (questionTextsToTranslate.length > 0) {
    const translatedQuestionTexts = await translateTexts(
      questionTextsToTranslate,
      [],
      targetLanguage
    );
    translatedQuestionTexts.forEach((translatedText, index) => {
      const question = questionMapping[index];
      if (question) {
        question.translatedQuestionText =
          translatedText || question.questionText;
      }
    });
  }

  if (optionNamesToTranslate.length > 0) {
    const translatedOptionNames = await translateTexts(
      optionNamesToTranslate,
      [],
      targetLanguage
    );
    translatedOptionNames.forEach((translatedOptionName, index) => {
      const option = optionMapping[index];
      if (option) {
        option.translatedOptionName = translatedOptionName || option.optionName;
      }
    });
  }
  return questions; // Returning the translated questions
};
