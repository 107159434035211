<template>
  <nav class="pt-4 w-100">
    <div
      :class="
        $route.name === 'SwitchApplication' ? 'container-fluid' : 'container'
      "
      class="d-flex justify-content-between align-items-center"
    >
      <div class="logo d-flex justify-space-between align-items-center">
        <img
          class="width-175"
          v-if="getAssets && getAssets.horizontalTeamLogo"
          :src="getAssets.horizontalTeamLogo"
          alt="Team-Logo"
        />
      </div>
      <div class="d-flex align-items-center right-desk-wrap">
      <div class="d-flex align-items-center">
        <!-- Add the image -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m11.9 22l4.55-12h2.1l4.55 12H21l-1.075-3.05h-4.85L14 22zM4 19l-1.4-1.4l5.05-5.05q-.875-.875-1.588-2T4.75 8h2.1q.5.975 1 1.7t1.2 1.45q.825-.825 1.713-2.313T12.1 6H1V4h7V2h2v2h7v2h-2.9q-.525 1.8-1.575 3.7t-2.075 2.9l2.4 2.45l-.75 2.05l-3.05-3.125zm11.7-1.8h3.6l-1.8-5.1z"
          />
        </svg>

        <!-- Dropdown displaying the full labels -->
        <div v-if="languageOptions && languageOptions.length > 0">
          <select v-model="language" @change="selectLanguage" class="language-btn"  :style="{ width: dynamicLanguageWidth }">
            <option
              v-for="lang in languageOptions"
              :value="lang.languageValue"
              :key="lang.languageValue"
            >
              {{ lang.langaugeLabel }}
            </option>
          </select>
        </div>
        <div v-else>
          <p>EN - English</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button
          type="button"
          :class="openSideBar ? 'active' : ''"
          @click="showSidebar"
          class="burger"
          id="burger"
        >
          <span class="burger-line"></span>
          <span class="burger-line"></span>
          <span class="burger-line"></span>
          <span class="burger-line"></span>
        </button>

          <div class="dropdown" v-if="getIsAuthenticated">
            <button
              type="button"
              id="my-account"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ translatedData?.myAccount }}
              <down-arrow />
            </button>
            <ul class="dropdown-menu" aria-labelledby="my-account">
              <li>
                <router-link to="/switch-application" class="dropdown-item">{{
                  translatedData?.switchApplication
                }}</router-link>
              </li>
              <li>
                <router-link to="/change-email" class="dropdown-item"
                  >{{ translatedData?.changeEmail }}l</router-link
                >
              </li>
              <li>
                <router-link to="/change-password" class="dropdown-item">{{
                  translatedData?.changePassword
                }}</router-link>
              </li>
              <li
                v-if="
                  getAppClientPortalURLs?.length &&
                  (currClientPortalUrl || pendingLatestAppCPUrl || latestAppCPUrl)
                "
              >
                <a
                  :href="currClientPortalUrl || pendingLatestAppCPUrl || latestAppCPUrl"
                  target="_blank"
                  class="dropdown-item"
                >
                  <div class="d-flex flex-direction-column">
                    <span class="custom-d-flex"
                      >{{ translatedData?.gotoClientPortal }} <SideArrow
                    /></span>
                    <span class="menu-subtext"
                      >{{ translatedData?.uploadDocuments }} <br />{{
                        translatedData?.completeTasks
                      }}</span
                    >
                  </div>
                </a>
              </li>
              <li>
                <a @click="logout" class="cursor-pointer dropdown-item">{{
                  translatedData?.signOut
                }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            <router-link to="/sign-in" class="text-secondary" role="button">
              {{ translatedData?.signIn }}
            </router-link>
          </div>
        </div>
      </div>
      </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import DownArrow from "./DownArrow.vue";
import { translateText } from "../translate-service";
import { translationMixin } from "../mixins/translationMixin";
import SideArrow from "./SideArrowSvg.vue";
import moment from "moment";
const navbarJsonData = {
  myAccount: "My Account",
  switchApplication: "Switch Application",
  changeEmail: "Change Email",
  changePassword: "Change Password",
  signOut: "Sign out",
  signIn: "Sign-In",
  gotoClientPortal: "Go to Client Portal",
  uploadDocuments: "Upload documents and",
  completeTasks: "complete tasks",
};
export default {
  name: "Navbar",
  mixins: [translationMixin],
  components: { DownArrow, SideArrow },
  data() {
    return {
      openSideBar: false,
      selectedLang: "English",
      data: navbarJsonData,
      language: null,
      languageOptions: null,
      dynamicLanguageWidth: "13ch",
    };
  },
  computed: {
    ...mapGetters([
      "getProspectId",
      "getOrgId",
      "getUserId",
      "getCompanyName",
      "getIsAuthenticated",
      "getAssets",
      "getApplications",
      "getAppClientPortalURLs",
      "getActiveSection",
      "getSocialLogin",
      "getFacebookData",
      "getFacebookScope",
      "getAppLanguage",
    ]),

    currClientPortalUrl() {
      let obj = this.getAppClientPortalURLs.find(
        (el) => el.prospectId === this.getUserId
      );

      return obj?.clientPortalURL || false;
    },

    pendingLatestAppCPUrl() {
      if (this.getApplications?.length) {
        let currentDate = moment().date("M/D/YYYY");

        let arrOfDays = this.getApplications.map((v) => {
          if (!v.endDate && v.startDate)
            return moment(currentDate).diff(moment(v.startDate), "days");
        });

        // Remove the negative numbers as they future dates
        let newArrOfDays = arrOfDays.filter((x) => {
          return x > -1;
        });

        if (newArrOfDays.length) {
          // Get the smallest positive number from the array
          let the_number = Math.min(...newArrOfDays);

          // returns index of the nearest start date
          let theIndex = arrOfDays.indexOf(the_number) || 0;

          let obj = this.getAppClientPortalURLs.find(
            (el) => el.prospectId === this.getApplications[theIndex].prospectId
          );

          return obj?.clientPortalURL || false;
        }
      }

      return false;
    },

    latestAppCPUrl() {
      if (this.getApplications?.length) {
        let currentDate = moment().date("M/D/YYYY");

        let arrOfDays = this.getApplications.map((v) => {
          return moment(currentDate).diff(moment(v.startDate), "days");
        });

        // Remove the negative numbers as they future dates
        let newArrOfDays = arrOfDays.filter((x) => {
          return x > -1;
        });

        if (newArrOfDays.length) {
          // Get the smallest positive number from the array
          let the_number = Math.min(...newArrOfDays);

          // returns index of the nearest start date
          let theIndex = arrOfDays.indexOf(the_number) || 0;

          let obj = this.getAppClientPortalURLs.find(
            (el) => el.prospectId === this.getApplications[theIndex].prospectId
          );

          return obj?.clientPortalURL || false;
        }
      }

      return null;
    },
  },
  created() {
    eventBus.$on("open-sidebar", (data) => {
      this.openSideBar = data;
    });
    this.languageOptions =
      this.getAssets?.dmaLangauges && this.getAssets?.dmaLangauges.length > 0
        ? this.getAssets?.dmaLangauges
        : [];
    this.language = this.getAppLanguage;
    const selectedLanguageLabel = this.languageOptions.find(
      (lang) => lang.languageValue === this.language
    );
    const selectedLanguageLength = selectedLanguageLabel?.langaugeLabel.length;
    this.dynamicLanguageWidth = `${selectedLanguageLength + 1}ch`;
  },
  methods: {
    ...mapActions([
      "fetchAppLanguage",
      "resetUserStore",
      "resetQuestionnaireStore",
      "resetAddressStore",
      "resetMcStore",
    ]),
    translateText,
    async selectLanguage() {
      // Find the selected language label for dynamic width adjustment
      const selectedLanguageLabel = this.languageOptions.find(
        (lang) => lang.languageValue === this.language
      );
      const selectedLanguageLength =
        selectedLanguageLabel?.langaugeLabel.length;

      // Dynamically set the width based on the label length
      this.dynamicLanguageWidth = `${selectedLanguageLength + 1}ch`;
      // Prepare payload for backend update
      const payLoad = {
        languageToUpdate: this.language,
        orgId: this.getOrgId,
        recordId: this.getProspectId,
      };
      // Fetch application language
      this.fetchAppLanguage(this.language);
      await this.$http.post("/account/GetDMAAsset/", payLoad);
    },
    showSidebar() {
      this.openSideBar = !this.openSideBar;
      eventBus.$emit("mobile-view", this.openSideBar);
    },

    async logout() {
      // Google signout
      if (this.getSocialLogin === "Google") await this.$gAuth.signOut();

      // Facebook signout
      if (this.getSocialLogin === "Facebook" && this.getFacebookScope.logout) {
        await this.getFacebookScope.logout();
      }

      this.resetUserStore();
      this.resetQuestionnaireStore();
      this.resetAddressStore();
      this.resetMcStore();

      this.$router.push("/log-out");
    },
  },
};
</script>

<style>
.width-175 {
  width: 175px;
  max-width: 175px;
}
.flex-direction-column {
  flex-direction: column;
}
.custom-d-flex {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.menu-subtext {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
  margin-top: 5px;
  min-width: 175px;
  max-width: 175px;
}
.dropdown .dropdown-menu li .dropdown-item:active .menu-subtext {
  color: #fff !important;
}
.language-btn {
  font-size: 16px;
  padding: 8px;
  border: none; /* Remove border */
  background-color: transparent; /* Ensure no background color */
  color: #333;
  cursor: pointer;
  width: auto;
  margin-right: 8px;
  appearance: none; /* Remove default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
}
.language-btn:focus {
  outline: none; /* Remove focus outline */
  box-shadow: none; /* Remove focus box shadow */
}
.language-btn:hover {
  border: none; /* Ensure no border on hover */
  background-color: transparent; /* Maintain transparent background */
}
@media (max-width: 900px) {
  .right-desk-wrap {
    justify-content: flex-end;
  }
}
</style>
