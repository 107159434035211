<template>
  <div class="bg-gray switch-application sa-main-div">
    <navbar />
    <div class="container-fluid">
      <div class="form-content">
        <div class="form-element">
          <form @submit.prevent>
            <div class="question">
              <h2>{{ translatedData?.chooseApplication }}</h2>
            </div>
            <div class="row">
              <div
                v-for="(app, index) in getApplications"
                :key="index"
                class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3"
              >
                <div class="application__cards">
                  <div class="application__cards-item">
                    <div class="icon">
                      <img src="../assets/images/primary-home.svg" />
                    </div>
                    <div class="date-title">
                      <span>{{ app.startDate | formatDate }}</span>
                      <div class="title">
                        {{ app.uniqueNumber || "Not Available" }}
                      </div>
                    </div>
                    <div class="divider"></div>
                    <div class="type-city">
                      <div class="type">
                        <span>{{ translatedData?.type }}</span>
                        <span>{{ app?.translatedType }}</span>
                      </div>
                      <div class="city">
                        <template v-if="app.type === 'Buying'">
                          <span>{{ translatedData?.occupancy }}</span>
                          <span>{{
                            app?.translatedOccupancy || "Not Available"
                          }}</span>
                        </template>
                        <template v-if="app.type === 'Refinance'">
                          <span>{{ translatedData?.city }}</span>
                          <span>{{ app?.city || "Not Available" }}</span>
                        </template>
                      </div>
                    </div>
                    <div class="buttons">
                      <button
                        type="button"
                        @click="continueWithApplication(app)"
                        v-if="!app.endDate"
                        class="btn btn-red"
                      >
                        <span>{{ translatedData?.continue }}</span>
                      </button>
                      <button v-else class="btn btn-red" disabled>
                        <span>{{ translatedData?.completed }}</span>
                      </button>
                      <a
                        class="btn-trash cursor-pointer"
                        data-bs-toggle="modal"
                        :data-bs-target="`#duplicateModal${app.prospectId}`"
                      >
                        <img src="../assets/images/copy-icon.svg" />
                      </a>
                    </div>
                  </div>
                </div>

                <!--Duplicate Modal start-->
                <div
                  class="modal fade"
                  :id="`duplicateModal${app.prospectId}`"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        <h3>{{ translatedData?.confirmDuplicateMessage }}</h3>
                        <p class="mt-3">
                          {{ translatedData?.duplicateMessage }}
                        </p>
                        <div class="button d-flex">
                          <button
                            class="btn btn-outline"
                            data-bs-dismiss="modal"
                          >
                            {{ translatedData?.cancel }}
                          </button>
                          <button
                            @click="cloneApplication(app.prospectId)"
                            class="btn btn-red"
                            data-bs-dismiss="modal"
                          >
                            <span>{{ translatedData?.duplicate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Duplicate Modal end-->
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                <div class="application__cards">
                  <div class="application__cards-item align-items-center">
                    <!-- <a
                      v-if="!showCreateAppModal"
                      class="icon plus-icon cursor-pointer"
                      @click="createApplication"
                    >
                      <i class="fa fa-plus"></i>
                    </a> -->
                    <a
                      class="icon plus-icon cursor-pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#copyAppModal"
                    >
                      <i class="fa fa-plus"></i>
                    </a>
                    <div class="date-title text-center">
                      <div class="title">
                        {{ translatedData?.wantToApplyAgain }}
                      </div>
                      <span class="font-18 mt-2">{{
                        translatedData?.startNewApplication
                      }}</span>
                    </div>
                  </div>
                </div>

                <!--Confirm Copy Application Modal start-->
                <div
                  class="modal fade"
                  id="copyAppModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        <h3>{{ translatedData?.confirmApplyAgainMessage }}</h3>
                        <p class="mt-3">
                          {{ translatedData?.applyAgainMessage }}
                        </p>
                        <div class="button d-flex">
                          <button
                            class="btn btn-outline"
                            data-bs-dismiss="modal"
                          >
                            {{ translatedData?.cancel }}
                          </button>
                          <button
                            @click="applyAgain(latestApplication)"
                            class="btn btn-red"
                            data-bs-dismiss="modal"
                          >
                            <span>{{ translatedData?.applyAgain }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Confirm Copy Application Modal end-->

                <!--Confirm New Application Modal start-->
                <!-- <div
                  class="modal fade"
                  id="createAppModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body">
                        <h3>
                          Looks like you already started an application but have
                          not yet finished
                        </h3>
                        <div class="button d-flex flex-column">
                          <button
                            @click="
                              pendingLatestApplication
                                ? continueWithApplication(
                                    pendingLatestApplication
                                  )
                                : ''
                            "
                            data-bs-dismiss="modal"
                            class="btn btn-red"
                          >
                            <span
                              ><strong
                                >Complete Existing Application</strong
                              ></span
                            >
                          </button>
                          <button
                            @click="createApplication"
                            data-bs-dismiss="modal"
                            class="btn btn-outline"
                          >
                            <strong>Create New Application</strong>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!--Confirm New Application Modal end-->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const switchApplicationtJson = {
  chooseApplication: "Choose Application",
  notAvailable: "Not Available",
  type: "Type",
  occupancy: "Occupancy",
  city: "City",
  completed: "Completed",
  continue: "Continue",
  duplicate: "Duplicate",
  cancel: "Cancel",
  applyAgain: "Apply",
  wantToApplyAgain: "Want to apply again?",
  startNewApplication: "Start a new application",
  confirmDuplicateMessage:
    "Are you sure you want to duplicate this application?",
  duplicateMessage: "All data you filled in earlier here will be duplicated.",
  confirmApplyAgainMessage: "Are you sure you want to apply again?",
  applyAgainMessage:
    "We will pre-fill your new application with the information you previously provided.",
  completeExistingApplication: "Complete Existing Application",
  createNewApplication: "Create New Application",
  buying: "Buying",
  refinance: "Refinance",
  date: "Date",
  title: "Title",
  plusIcon: "fa fa-plus",
  homeIcon: "primary-home.svg",
  copyIcon: "copy-icon.svg",
};
import { mapActions, mapGetters } from "vuex";
import Navbar from "./Navbar.vue";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { translationMixin } from "../mixins/translationMixin";
import { translateTexts } from "../translate-service-new";

export default {
  name: "Switch-Application",
  mixins: [translationMixin],
  components: { Navbar },
  data() {
    return {
      prevRoute: null,
      data: switchApplicationtJson,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters([
      "getProspectEmail",
      "getCompanyName",
      "getProspectId",
      "getOrgId",
      "getUserId",
      "getAppFlow",
      "getActiveQuestion",
      "getApplications",
      "getQuestions",
      "getCreateAppFlag",
      "getCloneAppId",
      "getCloneAppFlag",
      "getEditModeFlag",
      "getIsCoPilot",
      "getCoPilotEmail",
      "getActivePage",
    ]),

    baseUrl() {
      if (this.getIsCoPilot) {
        return (
          "/" +
          this.getCompanyName +
          "/" +
          this.getProspectId +
          "&" +
          this.getOrgId +
          "?email=" +
          this.getCoPilotEmail +
          "&password=&type=" +
          this.getAppFlow
        );
      }

      return (
        "/" +
        this.getCompanyName +
        "/" +
        this.getProspectId +
        "&" +
        this.getOrgId
      );
    },

    showCreateAppModal() {
      if (this.getApplications?.length) {
        return this.getApplications.some((v) => {
          if (!v.endDate && v.startDate) {
            let actualDate = moment(v.startDate);
            let currentDate = moment().date("M/D/YYYY");

            if (currentDate.diff(actualDate, "days") <= 7) return true;
            else return false;
          }

          return false;
        });
      }

      return false;
    },

    pendingLatestApplication() {
      if (this.getApplications?.length) {
        let currentDate = moment().date("M/D/YYYY");

        let arrOfDays = this.getApplications.map((v) => {
          if (!v.endDate && v.startDate)
            return moment(currentDate).diff(moment(v.startDate), "days");
        });

        // Remove the negative numbers as they future dates
        let newArrOfDays = arrOfDays.filter((x) => {
          return x > -1;
        });

        if (newArrOfDays.length) {
          // Get the smallest positive number from the array
          let the_number = Math.min(...newArrOfDays);

          // returns index of the nearest start date
          let theIndex = arrOfDays.indexOf(the_number) || 0;

          return this.getApplications[theIndex];
        }
      }

      return null;
    },

    latestApplication() {
      if (this.getApplications?.length) {
        let currentDate = moment().date("M/D/YYYY");

        let arrOfDays = this.getApplications.map((v) => {
          return moment(currentDate).diff(moment(v.startDate), "days");
        });

        // Remove the negative numbers as they future dates
        let newArrOfDays = arrOfDays.filter((x) => {
          return x > -1;
        });

        if (newArrOfDays.length) {
          // Get the smallest positive number from the array
          let the_number = Math.min(...newArrOfDays);

          // returns index of the nearest start date
          let theIndex = arrOfDays.indexOf(the_number) || 0;

          return this.getApplications[theIndex];
        }
      }

      return null;
    },
  },
  mounted() {
    if (this.getCreateAppFlag) this.fetchCreateAppFlag(false);
    if (this.getCloneAppFlag) this.fetchCloneAppFlag(false);
    if (this.getCloneAppId) this.fetchCloneAppId("");

    this.getAllUserApplications();
  },
  methods: {
    ...mapActions([
      "fetchLoaderStatus",
      "fetchUserId",
      "fetchUser",
      "fetchAppFlow",
      "fetchActiveQuestion",
      "fetchActiveSection",
      "fetchApplications",
      "fetchAppClientPortalURLs",
      "fetchSections",
      "fetchQuestions",
      "fetchSubQuestions",
      "fetchCreateAppFlag",
      "fetchCloneAppId",
      "fetchCloneAppFlag",
      "fetchApplyAgainFlag",
      "fetchEditModeFlag",
      "updateQueObjectWithAnswer",
      "updateApplications",
      "fetchActivePage",
    ]),

    async getAllUserApplications() {
      await this.$http
        .get(
          "/account/getAllMultipleApplicationData?prospectEmail=" +
            this.getProspectEmail +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            // Translate application data
            const applicationTransaltedData =
              await this.translateApplicationData(resp.applications);
            this.fetchApplications(applicationTransaltedData || []);
            this.fetchAppClientPortalURLs(
              resp?.applicationClientPortalLinks || []
            );

            // Redirect user to questionnaire page if only single pending application is available
            if (
              (this.prevRoute.name === "SignIn" ||
                this.prevRoute.name === "SignUp") &&
              resp.applications &&
              resp.applications.length === 1 &&
              !resp.applications[0].endDate
            ) {
              await this.continueWithApplication(resp.applications[0]);
            }

            this.fetchLoaderStatus(false);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },
    async translateApplicationData(applicationData) {
      const targetLanguage = this.getAppLanguage;
      console.log(targetLanguage, "targetLanguage");
      const keysToTranslate = ["type", "occupancy", "city"]; // Keys to translate

      try {
        // If application data exists and is an array
        if (applicationData && applicationData.length > 0) {
          // Translate fields in each application object
          const applicationDataWithTranslations = await Promise.all(
            applicationData.map(async (applicationObj) => {
              let applicationCopy = { ...applicationObj }; // Create a copy of the application object

              // Extract the fields to be translated for the current application object
              const fieldsToTranslate = keysToTranslate
                .map((key) => applicationObj[key])
                .filter(Boolean);

              // If there are fields to translate, batch translate them
              if (fieldsToTranslate.length > 0) {
                // Translate the fields in bulk
                const translatedFields = await translateTexts(
                  fieldsToTranslate,
                  [],
                  targetLanguage
                );

                // Assign the translated fields back to the application object, with new keys for translations
                keysToTranslate.forEach((key, index) => {
                  if (fieldsToTranslate[index]) {
                    const translatedKey = `translated${
                      key.charAt(0).toUpperCase() + key.slice(1)
                    }`; // Capitalize the key for the translated version
                    applicationCopy[translatedKey] =
                      translatedFields[index] || applicationObj[key]; // Add translated value or fallback
                  }
                });
              }

              return applicationCopy;
            })
          );
          console.log(
            applicationDataWithTranslations,
            "applicationDataWithTranslations"
          );
          // Update the fetched application data with translated values
          return applicationDataWithTranslations;
        } else {
          // Handle case where applicationData is empty or invalid
          console.warn("No application data provided for translation.");
        }
      } catch (error) {
        console.error("Error fetching translated application data:", error);
      }
    },

    async continueWithApplication(app) {
      this.fetchActivePage(true);
      this.fetchActiveQuestion(1);
      this.fetchActiveSection("Getting Started");
      this.fetchLoaderStatus(true);

      if (app.type !== this.getAppFlow) await this.getQuestionnaire(app);

      let theQues = cloneDeep(this.getQuestions);

      await this.$http
        .get(
          "/account/getSingleQuestionAnswer?prospectId=" +
            app.prospectId +
            "&orgId=" +
            this.getOrgId +
            "&flowName=" +
            app.type
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchUserId(resp.prospectId);
            this.fetchUser(resp.prospectdetails);
            this.updateQueObjectWithAnswer(resp.appQuestions);

            // Save unauthenticated questions after successful login
            if (theQues.length && this.getUserId)
              this.storeUnauthenticatedQuestions(theQues);
            if (this.getEditModeFlag) this.fetchEditModeFlag(false);
            this.$router.push("/questionnaire");
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    storeUnauthenticatedQuestions(data) {
      let unsavedQues = data.filter(
        (v) =>
          !v.needsAuthentication &&
          v.apiName === "LockLearProspectAuth" &&
          v.questionAnswer !== null &&
          v.questionAnswer !== "" &&
          v.questionAnswer != undefined
      );

      if (unsavedQues.length && this.getUserId) {
        // payLoad for API
        let payLoad = {
          prospectId: this.getUserId,
          questionsToUpdate: unsavedQues,
          orgId: this.getOrgId,
        };

        this.$http
          .post("/question/prospect/Questions/", payLoad)
          .then((response) => {
            let resp = response.data;

            if (resp.response === "Success")
              this.updateQueObjectWithAnswer(resp.appQuestions);

            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }
    },

    async getQuestionnaire(app) {
      this.fetchAppFlow(app.type);

      await this.$http
        .get(
          "/question/GetProspectQuestions/" +
            this.getAppFlow +
            "/" +
            app.prospectId +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchSections(resp.sidebarSections);
            this.fetchQuestions(resp.appQuestions);
            this.fetchSubQuestions(resp.subQuestions);
            this.fetchActiveQuestion(1);

            let getSectionName = this.getQuestions.find(
              (v) => v.questionIndex === this.getActiveQuestion
            );

            this.fetchActiveSection(getSectionName.sectionName);
          }

          if (resp.response === "Error") this.err(resp.message);
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });
    },

    // createApplication() {
    //   this.fetchLoaderStatus(true);
    //   this.fetchCreateAppFlag(true);
    //   this.$router.push(this.baseUrl);
    // },

    cloneApplication(prospect_id) {
      this.fetchActivePage(false);
      this.fetchCloneAppFlag(true);
      this.fetchCloneAppId(prospect_id);
      this.$router.push(this.baseUrl);
    },

    async applyAgain(latestapp) {
      this.fetchLoaderStatus(true);

      let payLoad = {
        copyApplication: true,
        flowName: latestapp.type,
        orgId: this.getOrgId,
        prospectId: latestapp.prospectId,
      };

      await this.$http
        .post("/account/createAndCopyDMAApplicationAPI", payLoad)
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(resp.message);
            this.updateApplications(resp.applications);
            this.fetchApplyAgainFlag(true);

            await this.continueWithApplication(resp.applications[0]);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },
  },
};
</script>

<style scoped>
@import "./../assets/css/switch-application.css";

.switch-application .application__cards-item .plus-icon {
  background-color: var(--primary-color);
}

.heighted-btn {
  height: 70px !important;
}
</style>
