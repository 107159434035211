const consentRecordObject = {
    "modal_title": "Notice and Agreement Regarding Consent to Use Electronic Signatures and Records",
    "introduction": "To use electronic signatures and receive documents electronically in connection with your use of this platform (“Platform”), you must read and consent electronically to the terms outlined in this document (“eConsent”), which require your ability to access and retain electronic documents. This eConsent, if you provide it, applies to your use of this Platform on any device, including a desktop, laptop, tablet, mobile, or other electronic device (“Access Device”), and to any document we provide to you in electronic form, which may include messages, notices, disclosures, consents, authorizations, acknowledgments, and terms and conditions, whether required by law or otherwise (“Document(s)”). If you provide eConsent, we will be able to provide electronic Documents to you within this Platform, in other portals, and/or through other methods we may use for delivery of electronic Documents.",
    "definitions": "Please note these additional definitions:",
    "we":"We",
    "our":"our",
    "and":"and",
    "us":"us",
    "you":"You",
    "your":"your",
    "means_your_financial":"means your financial",
    "means_your_financial_1":"institution and its current and future affiliates, successors, and assignees as well as CandidApp LLC (“CANDID”) and its current and future affiliates; CANDID is the provider of this Platform." ,
    "means_your_financial_2":"means the person providing this",
    "means_your_financial_3":"eConsent, including any authorized signer, user, representative, delegate, and/or service user.",
    "scope_of_eConsent": "Scope of your eConsent",
    "scope_of_eConsent_description_1": "We must, due to legal and other requirements, provide you with certain Documents in writing. In addition, you may need to sign certain Documents. Rather than using paper records and ink signatures, we may, with your consent, use electronic records and signatures throughout our relationship with you.",
    "scope_of_eConsent_description_2":"Your eConsent will apply to all of the Documents transmitted, received, delivered, and/or signed by you and by us in connection with your use of this Platform, in other portals, and/or through other methods we may use for delivery of electronic Documents.",
    "right_to_paper_version": "Right to have a paper version of your Documents",
    "right_to_paper_version_description": "We are required to give you certain Documents “in writing,” which means you are entitled to receive a paper version of the Documents. However, in order to use this Platform, you must provide consent for us to provide these Documents to you electronically. If you do not provide eConsent, you will not be able to use the Platform.",
    "right_to_paper_version_description_1":"If you provide eConsent, your Documents will be delivered electronically so that you can access the Documents and retain them for future access. If you want to ensure that you continue to have access to these Documents, you must save them onto an Access Device or external storage device.",
    "right_to_paper_version_description_2":"You have the right to receive a paper version of your Documents, even if you have provided eConsent and we deliver the Documents electronically. You may have to pay a fee for a paper version unless charging a fee is prohibited by applicable law.",
    "paper_request_contact": "If you want a paper version of the Documents provided by your financial institution or its affiliates, contact",
    "paper_request_contact_1":"If you want a paper version of the Documents provided by CANDID or its affiliates, email info@candid.com.",
    "paper_request_contact_2":"In addition, we may, at any time, in our sole discretion, provide you with or require you to use a paper version of the Documents, even if you have provided eConsent.",
    "right_to_withdraw_eConsent": "Right to withdraw your eConsent",
    "right_to_withdraw_eConsent_description": "If you have provided eConsent, you have the right to withdraw your eConsent at any time and instead receive a paper version of your Documents.",
    "withdraw_eConsent_contact": "If you decide to withdraw your eConsent as it relates to your financial institution or its affiliates, contact",
    "withdraw_eConsent_contact_1":"If you decide to withdraw your eConsent as it relates to CANDID or its affiliates, email",
    "withdraw_effective": "Your eConsent withdrawal will become effective after we have had a reasonable opportunity to act upon it. If you withdraw your eConsent, you will not be able to use the Platform.",
    "obligation_update_email": "Obligation to update your email address",
    "obligation_update_email_description": "You must provide us with your own valid and current email address where you can receive email, access hyperlinks, and access electronic Documents. You must ensure that we always have your valid and current email address, even if you have provided eConsent and we deliver your Documents electronically through the Platform. To update your email address or other contact information, contact",
    "contact_candid":"contact CANDID",
    "hardware_software_requirements": "Hardware and software requirements",
    "hardware_software_requirements_description": "Before providing eConsent, you must ensure that the hardware and software of any Access Device(s) you use meet the requirements below.",
    "internet_access": "Internet access – 1.5 Mbps per second or better",
    "disc_space": "Disc space – Sufficient memory on Access Device or external storage device to retain Documents",
    "pdf_reader": "Latest supported version of Adobe Acrobat Reader or Current Version – If you do not have a PDF reader, you may download one for free at:",
    "pdf_reader_1":"Take our PDF test (PDF)",
    "pdf_reader_2":"to confirm that you have the PDF reader software required to view and save PDF files.",
    "printer": "Printer - Installed printer to print disclosures",
    "ssl_encryption": "SSL encryption enabled",
    "email_address": "An active email address capable of receiving and sending electronic messages",
    "javascript_enabled": "Javascript enabled",
    "security_settings_enabled": "Security settings enabled",
    "webcam_required": "If applicable services are used, a webcam, microphone, and speaker to support a live, real-time audio video connection, and a mobile device with text message functionality and a camera",
    "desktop_requirements": "Any desktop or laptop device you use must meet the following additional requirements, as applicable:",
    "desktop_os": "Operating system – Latest supported version of",
    "windows":"Windows",
    "mac_os":"mac OS",
    "desktop_browser": "Internet browser – Latest supported version of",
    "chrome":"Google Chrome",
    "mie":"Microsoft Internet Explorer",
    "edge":"Microsoft Edge",
    "mozilla":"Mozilla Firefox",
    "safari":"Apple Safari",
    "tablet_mobile_requirements": "Any tablet, mobile, or other device you use must meet the following additional requirements, as applicable:",
    "tablet_mobile_requirements_1":"iPhone",
    "tablet_mobile_requirements_2":"supported models",
    "tablet_mobile_requirements_3":"iOS – Latest fully-patched version",
    "tablet_mobile_iphone_1": "iPhone",
    "tablet_mobile_iphone_2": "supported models",
    "tablet_mobile_iphone_3": "iOS – Latest fully-patched version",
    "tablet_mobile_ipad_1": "iPad",
    "tablet_mobile_ipad_2": "supported models",
    "tablet_mobile_ipad_3": "iOS – Latest fully-patched version",
    "tablet_mobile_android": "Android phone, Android OS – Latest fully-patched version",
    "tablet_mobile_browser": "Internet browser — Latest supported version of Google Chrome or Apple Safari",
    "platform_optimization": "Note: this Platform is optimized for use on Google Chrome. You may download Google Chrome for free at: ",
    "platform_optimization_1":". If you use other software, you may not receive the same level of support or performance, but as long as you are able to read and review the information above and otherwise access PDF documents, you should be able to access and retain Documents provided through this Platform.",
    "changes_to_notice": "Changes to this Notice Regarding Consent to Use Electronic Signatures and Records, including changes to hardware or software requirements",
    "changes_to_notice_description": "We will notify you if there are changes to the terms of your eConsent or to the hardware or software requirements that could materially affect your eConsent or your ability to access or retain your Documents. The notice may require you to reaffirm your eConsent and your ability to access and retain electronic Documents, or may indicate that you can reaffirm your eConsent by continuing to access your Documents electronically. The notice will remind you of your right to withdraw your eConsent, and how to do so if you choose that option.",
    "obligation_change_device": "Obligation regarding change of Access Device",
    "obligation_change_device_description": "If you change your Access Device at any point during your use of this Platform or anytime during our relationship with you, it is your responsibility to ensure that the new Access Device meets the hardware and software requirements listed above and that you are still able to access and retain Documents using the new Access Device. By continuing to access your Documents electronically, you reaffirm your eConsent.",
    "consent_agreement": "By providing your consent, you indicate your agreement to and confirmation of the following:",
    "consent_agreement_1": "I am able to access these terms regarding eConsent.",
    "consent_agreement_2": "I consent to sign and receive Documents electronically in place of paper documents. My electronic signature has the same effect as if I were to sign in ink.",
    "consent_agreement_3": "I have reviewed the hardware and software requirements, and agree that my Access Device(s) meet these requirements. I understand that it is my responsibility to ensure that any Access Device(s) I use in the future meet these requirements.",
    "consent_agreement_4": "I have provided my own valid and current email address, where I can receive email, access hyperlinks, and access electronic Documents. I will ensure that my financial institution and its affiliates as well as CANDID and its affiliates always have a valid and current email address for contacting me.",
    "consent_agreement_5": "My eConsent applies to all of my activities in connection with my use of this Platform, in other portals, and/or through other methods we may use for delivery of electronic Documents.",
    "close_button": "Close",
    "at":"at",
    "or":"or",
  };
  export default consentRecordObject
  