import axios from './api-service';

const apiKey = 'AIzaSyCZDzkAn6CYRk0M_GygBrJc8ETq-BaXpb0'; // Replace with your actual API key


async function translateText(data, keysToTranslate = [], targetLang = 'en') { 
    if (data === null) {
        // Preserve null values
        return null;
    } else if (typeof data === 'string') {
        // Translate standalone strings
        return await translateString(data, targetLang);
    } else if (Array.isArray(data)) {
        // Handle arrays: recursively translate each element
        return await Promise.all(
            data.map(item => translateText(item, keysToTranslate, targetLang))
        );
    } else if (typeof data === 'object' && data !== null) {
        const translatedObject = { ...data }; // Copy the original object

        // Translate only the specified keys if they exist
        for (const key of keysToTranslate) {
            if (key in data && typeof data[key] === 'string') {
                translatedObject[key] = await translateString(
                    data[key],
                    targetLang
                );
            }
        }

        return translatedObject;
    } else {
        // Return other non-translatable data types as-is
        return data;
    }
}

async function translateString(text, targetLang) {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    try {
        if ("en" === targetLang) {
          return text;
        } else {
          const response = await axios.post(url, {
            q: text, // Send an array of texts
            source: "en",
            target: targetLang,
          });
          // Return the translated texts as an arrays
          return response?.data?.data?.translations[0]?.translatedText;
        }
      } catch (error) {
        console.error('Translation error:', error);
        return text; // Fallback to original text if translation fails
    }
}

export { translateText };
