import { mapGetters } from "vuex";
import { translateTexts } from "../translate-service-new"; // Import your batch translation function

export const sideBartranslationMixin = {
  computed: {
    ...mapGetters(["getAppLanguage"]),
  },
  methods: {
    translateTexts,

    async translateFields(data) {
      const targetLanguage = this.getAppLanguage;
      const fieldsToTranslate = [
        {
          original: "sidebarAbbreviationText",
          translated: "translatedSidebarAbbreviationText",
        },
      ];

      // Arrays to collect all texts to be translated and their corresponding fields to update
      const textsToTranslate = [];
      const fieldsToUpdate = [];

      // Collect texts for translation
      data.forEach((item) => {
        fieldsToTranslate.forEach(({ original, translated }) => {
          if (
            original === "sidebarAbbreviationText" &&
            item["showOnSidebar"] &&
            (item["questionAnswer"] ||
              item["sidebarAbbreviationText"] === "OptionAnswer:Total") &&
            item["sidebarAbbreviationText"]
          ) {
            textsToTranslate.push(item[original]);
            fieldsToUpdate.push({ item, translated, original });
          }
        });
      });

      try {
        // Translate all texts in one go
        if (textsToTranslate.length > 0) {
          const translatedTexts = await this.translateTexts(
            textsToTranslate,
            [],
            targetLanguage
          );

          // Map the translations back to their corresponding fields
          let translationIndex = 0;
          fieldsToUpdate.forEach(({ item, original, translated }) => {
            // Handle both single value and array cases
            if (Array.isArray(item[original])) {
              item[translated] = item[original].map(
                () => translatedTexts[translationIndex++]
              );
            } else {
              item[translated] = translatedTexts[translationIndex++];
            }
          });
        }
      } catch (error) {
        console.error("Translation failed:", error);
        return data; // Return the original data if translation fails
      }

      return data; // Return the data after translation
    },
  },
};
