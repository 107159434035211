export const translateFormQuestionsAndOptions = async (
  questions,
  translateTexts,
  targetLanguage
) => {
  // Collect texts to translate
  const textsToTranslate = [];
  const mapping = [];

  // Loop through each question and collect the necessary texts
  questions.forEach((question) => {
    // Collect question text
    if (question.questionText) {
      textsToTranslate.push(question.questionText);
      mapping.push({ type: "questionText", question });
    }

    // Collect question options
    if (question.questionOptions && Array.isArray(question.questionOptions)) {
      question.questionOptions.forEach((option) => {
        if (option.optionName) {
          textsToTranslate.push(option.optionName);
          mapping.push({ type: "optionName", question, option });
        }
      });
    }

    // Collect question links
    if (question.questionLink) {
      textsToTranslate.push(question.questionLink);
      mapping.push({ type: "questionLink", question });
    }

    // Collect question link descriptions
    if (question.questionLinkDescription) {
      textsToTranslate.push(question.questionLinkDescription);
      mapping.push({ type: "questionLinkDescription", question });
    }
  });

  // Perform batch translation if there are any texts to translate
  if (textsToTranslate.length > 0) {
    try {
      // Perform the translation in a single call
      const translatedTexts = await translateTexts(
        textsToTranslate,
        [],
        targetLanguage
      );

      // Map translated texts back to their corresponding fields
      translatedTexts.forEach((translatedText, index) => {
        const mappingInfo = mapping[index];
        const { type, question, option } = mappingInfo;

        switch (type) {
          case "questionText":
            question.translatedQuestionText =
              translatedText || question.questionText;
            break;
          case "optionName":
            option.translatedOptionName = translatedText || option.optionName;
            break;
          case "questionLink":
            question.questionLink = translatedText || question.questionLink;
            break;
          case "questionLinkDescription":
            question.questionLinkDescription =
              translatedText || question.questionLinkDescription;
            break;
        }
      });
    } catch (error) {
      console.error("Translation error:", error);
    }
  }

  return questions; // Return the translated questions
};
