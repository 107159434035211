<template>
  <div>
    <form @submit.prevent>
      <div class="question">
        <!-- Question Text -->
        <h2>{{ questionTitle }}</h2>

        <!-- Question Description -->
        <p class="mt-3" v-show="question.questionDescription">
          {{ questionDesc }}
        </p>

        <!-- Question Sub Group Name -->
        <p class="personal-info mt-3" v-show="question.subGroupName">
          <span>{{ questionSubGName }}</span>
        </p>
      </div>
      <div class="accordion">
        <p v-if="showCoApplicant" class="personal-info mt-4 mb-4">
          {{ translatedData?.chooseAnsewer }} {{ getApplicantName }}
        </p>
        <ul v-if="answerOptions.length">
          <li v-for="(option, index) in answerOptions" :key="index">
            <div class="title-amt">
              <div class="radio-button">
                <input
                  type="radio"
                  :id="option.optionValue"
                  :value="option.optionValue"
                  v-model.trim="radioAnswer"
                />
                <label :for="option.optionValue" role="button">{{
                  option.optionName
                }}</label>
              </div>
            </div>
          </li>
        </ul>

        <p v-if="showCoApplicant" class="personal-info mt-4 mb-4">
          {{ translatedData?.chooseAnsewer }} {{ getCoApplicantName }}
        </p>
        <ul v-if="showCoApplicant">
          <li v-for="(option, index) in question.questionOptions" :key="index">
            <div class="title-amt">
              <div class="radio-button">
                <input
                  type="radio"
                  :id="'co' + option.optionValue"
                  :value="option.optionValue"
                  v-model="coRadioAnswer"
                />
                <label :for="'co' + option.optionValue" role="button">{{
                  option.optionName
                }}</label>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="button mt-4 justify-content-between">
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-outline"
            @click="previousQuestion"
          >
            <span>{{ translatedData?.previous }}</span>
          </button>
          <button
            type="button"
            class="btn"
            :class="!disableButton ? 'btn-red' : ''"
            :disabled="disableButton"
            @click="saveTheData"
          >
            <span>{{ translatedData?.continue }}</span>
          </button>
        </div>

        <div class="align-text-right col-sm-6">
          <a
            v-if="question.questionLink"
            :data-bs-toggle="
              question.questionLink && question.questionLinkDescription
                ? 'modal'
                : ''
            "
            data-bs-target="#infoModal"
            class="text-underline"
            >{{ translatedData?.questionLink }}</a
          >
        </div>

        <info-modal
          v-if="question?.questionLink && question?.questionLinkDescription"
          :title="translatedData?.questionLink"
          :description="translatedData?.questionLinkDescription"
          :close="translatedData?.close"
        />
      </div>
    </form>
  </div>
</template>

<script>
import commonFunctions from "./../../mixins/commonfunctions";
import { translationMixin } from "../../mixins/translationMixin";
import InfoModal from "../InfoModal.vue";

export default {
  name: "Radio",
  mixins: [commonFunctions, translationMixin],
  components: { InfoModal },
  data() {
    return {
      radioAnswer: "",
      coRadioAnswer: "",
      data: {
        chooseAnsewer: "Choose an answer for",
        continue: "Continue",
        previous: "Previous",
        close: "Close",
        questionLink: this.question?.questionLink,
        questionLinkDescription: this.question?.questionLinkDescription,
      },
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  computed: {
    disableButton() {
      if (this.showCoApplicant) {
        if (
          this.radioAnswer === "" ||
          this.radioAnswer === null ||
          this.coRadioAnswer === "" ||
          this.coRadioAnswer === null
        )
          return true;
      } else {
        if (this.radioAnswer === "" || this.radioAnswer === null) return true;
      }

      return false;
    },

    showCoApplicant() {
      let que = this.question;

      if (que.questionCoFieldMapping && que.coDependentQuestionId) {
        let checkIt = this.getQuestions.some(
          (v) =>
            v.questionId === que.coDependentQuestionId &&
            v.questionAnswer === que.coDependentQuestionAnswer
        );
        if (checkIt) return true;
        if (!checkIt) return false;
      }

      return false;
    },
  },
  mounted() {
    this.checkTheDependancy(this.question);

    this.radioAnswer =
      this.question.questionAnswer || this.question.defaultQuestionAnswer;
    this.coRadioAnswer =
      this.question.coQuestionAnswer || this.question.defaultQuestionAnswer;
  },
  methods: {
    async saveTheData() {
      this.fetchCompLoaderStatus(true);

      let payLoad = {};

      payLoad = {
        question_id: this.question.questionId,
        answer: this.radioAnswer,
        co_answer: this.coRadioAnswer,
      };

      this.addAnswerLocally(payLoad);

      if (this.getUserId && this.question.apiName === "LockLearProspectAuth") {
        let theQuestion = this.question;
        theQuestion.questionAnswer = this.radioAnswer;
        theQuestion.coQuestionAnswer = this.coRadioAnswer;

        // payLoad for API
        payLoad = {
          prospectId: this.getUserId,
          questionsToUpdate: [theQuestion],
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/question/prospect/Questions/", payLoad)
          .then((response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              if (this.getEditModeFlag) this.fetchEditModeFlag(false);

              this.updateQueObjectWithAnswer(resp.appQuestions);
              this.nextQuestion();
            }

            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      if (!this.getUserId || this.question.apiName === null) {
        if (this.getEditModeFlag) this.fetchEditModeFlag(false);
        this.nextQuestion();
      }

      this.fetchCompLoaderStatus(false);
    },

    nextQuestion() {
      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      ) {
        this.$router.push("/submit-application");
      } else {
        this.fetchActiveQuestion(this.getActiveQuestion + 1);
      }
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.getActiveQuestion - 1);
    },
  },
};
</script>
