const privacyObject ={
    "title": "CANDID Platform Consumer Privacy Policy",
    "updated_effective_date": "Updated/Effective date: July 28, 2020",
    "privacy_policy_description": "This Privacy Policy applies to the products, services, and platform (referred to collectively as the CANDID” or Platform”) owned and operated by CandidApp LLC. (CANDID” or us” or our” or we”). The Platform allows you to efficiently provide and consolidate information that banks, mortgage companies, and other lenders (Financial Services Providers”) need to process loan applications and offer other financial services to you. For purposes of this Policy, you” and your” mean the Platform account creator, authorized signer, authorized representative, delegate, and/or other authorized Platform user, including a co-applicant. Except as provided herein, this Policy describes and governs the information collection, use, and sharing practices of CANDID with respect to the Platform.",
    "review_policy_note": "Before you use or submit any information on or through the Platform, please carefully review this Policy. By using any part of the Platform, you consent to the collection, use, and disclosure of the information you provide as further outlined in this Policy. We will continue to evaluate this Policy as we update and expand the Platform and our offerings, and we may make changes to the Policy accordingly. Any changes will be incorporated into this Policy and posted on the Platform. Your continued use of the Platform will signify acceptance of the terms of the updated Policy.",
    "privacy_joint_applicants_title": "1. Privacy of Joint Applicants",
    "privacy_joint_applicants_info": "If you are using the Platform with another person to apply jointly for a loan or other financial services product, you and your co-applicant may choose to provide information for your application using a single joint Platform account or multiple individual Platform account(s). If joint applicants choose to utilize a single Platform account, they will have access to all information supplied by Platform account users to complete that application. Such joint applicants will have the ability to view, print, transmit, save, and/or edit the information. Access may continue for as long as the joint applicants utilize the single Platform account in connection with the application process.",
    "candid_collect_info_title": "2. What Information Does CANDID Collect?",
    "categories_of_information_title": "a. Categories of Information",
    "categories_of_information_content": "We collect the following categories of information. The exact information we collect about you depends on the data collection practices of your Financial Services Provider, what products/services you use and how you use our Platform and interact with us. We base the information in this disclosure on our data collection practices over the previous 12 months until present time.",
    "identifiers": "Identifiers (such as a real name, postal address, IP address, email address, social security number, or other similar identifiers);",
    "commercial_information": "Commercial information (such as transactional data or records of personal property, or other purchasing or consuming histories or tendencies);",
    "financial_information": "Financial information (such as financial history);",
    "internet_device_activity": "Internet or other network or device activity (such as browsing history and log data);",
    "geolocation_information": "Geolocation information (such as general location information inferred from an IP address);",
    "professional_employment_data": "Professional or employment related data (such as information you provide about your work history);",
    "education_data": "Education data (such as information about your educational institutions and history);",
    "protected_classification_data": "Characteristics of protected classifications under state or federal law (such as gender and marital status);",
    "insurance_information": "Insurance information;",
    "physical_characteristics": "Physical characteristics or description;",
    "inference_data": "Inference data about you (such as profiles we develop based on how you use our Platform);",
    "other_information": "Other information that identifies or can be reasonably associated with you.",
    "categories_of_sources_title": "b. Categories of Sources of Information",
    "categories_of_sources_content": "We collect information in multiple ways, including when you provide information directly to us and when we passively collect information from you, such as from your browser or device.",
    "information_provided_directly_title": "Information You Provide Directly to Us",
    "information_provided_directly_content": "We may collect information from you during your use of or access to the Platform, such as when you:",
    "register_account": "Register for a CANDID Platform account;",
    "enter_information": "Enter information in the CANDID Platform;",
    "communicate_with_us": "Communicate with us; or",
    "connect_third_party": "Connect with your third-party accounts to retrieve your information.",
    "example_registration_process": "For example, through the registration process and/or through your Platform account settings, we may collect information such as your email address, account password, and recovery phone number for creating and authenticating a CANDID Platform account (CANDID Account Credentials”). We may also collect third-party account credentials (e.g., your log-in credentials for accessing your bank account or tax return information) (Third-Party Account Credentials”), as well as contact, financial, and other information that may be used for the purpose of retrieving information from third-parties. We will also collect information from the accounts you authorize us and others to access on our or the Financial Services Provider’s behalf that is used in connection with applications for financial services products. All such information we collect from you through or in connection with the Platform is covered by this Policy.",
    "information_collected_passively_title": "Information Collected Passively",
    "device_usage_information_title": "Device/usage information:",
    "device_usage_information_content": "We may automatically collect certain information about the computer or devices (including mobile devices or tablets) you use to access the Platform. We may collect and analyze information such as (a) IP addresses (including city and state information), unique device identifiers, IMEI and TCP/IP addresses, and other information about your computer or device(s), browser types, browser language, operating system, mobile device carrier information, and the state or country from which you accessed the Platform; and (b) information related to the ways in which you interact with the Platform, such as referring and exit web pages and URLs, platform type, number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, statistical information about the use of the Platform, amount of time spent on particular pages, date and time you used the Platform, frequency of your use of the Platform, error logs, and other similar information. As described further below, we may use third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting this information.",
    "cookies_technologies_title": "Cookies and Other Electronic Technologies:",
    "cookies_technologies_content": "We may also collect data about your use of the Platform through the use of internet server logs, cookies, and/or tracking pixels. An internet server log is a file where website activity is stored. A cookie is a small text file that is placed on your computer when you visit a website that enables us to: (i) recognize your computer; (ii) store your preferences and settings; (iii) understand the web pages of the Platform you have visited; (iv), enhance your user experience by delivering content specific to your interests; (v) perform searches and analytics; and (vi) assist with security administrative functions. Some cookies are placed in your browser cache. Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier that are embedded in websites, online ads, and/or email and are designed to provide usage information like page clicks, measure popularity of the Platform and associated content, and access user cookies. We may update this Policy from time to time as we adopt new technologies to gather additional information through other methods.",
    "blocking_cookies_title": "Blocking cookies through your browser settings",
    "blocking_cookies_content": ': Please note that you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please consult the "Help" section of your browser (e.g.,',
    "connect_third_party_ie": "Internet Explorer",
    "connect_third_party_chrome": "Google Chrome",
    "connect_third_party_firefox": "Mozilla Firefox",
    "connect_third_party_safari": "Apple Safari",
    "connect_third_party_end": ") for more information. Please note that by blocking any or all cookies, you may not have access to certain features or offerings of the Platform.",
    "information_from_third_parties_title": "Information from Third Parties",
    "information_from_third_parties_content": "We may also obtain information about you from third parties such as other financial institutions with which you have a relationship, third-party data access providers (see below) and credit reporting agencies, among others. These third parties provide data, products, and services that enable us to provide the Services through the Platform.",
    "how_candid_uses_information_title": "3. How Will CANDID Use My Information?",
    "how_candid_uses_information_content": "CANDID may use the information we collect from and about you for business purposes (as defined by applicable law) and commercial purposes, such as:",
    "performing_services": "Performing services on behalf of the business or other service providers, including maintaining or servicing accounts, processing or fulfilling transactions, verifying customer information, processing payments, providing financing, providing analytic services, or providing similar services on behalf of the business or service provider. Specifically, this may include:",
    "processing_transaction": "Processing and fulfilling a transaction or providing products or services through or in connection with the Platform (e.g., to pull relevant information from your financial accounts to assist in compiling documentation for your Financial Services Provider and others);",
    "facilitating_registration": "Facilitating your registration for a CANDID Platform account;",
    "sending_activity_information": "Sending you information about your Platform-related activities;",
    "detecting_security_incidents": "Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.",
    "debugging_platform": "Debugging to identify and repair errors that impair existing intended functionality (i.e. to administer and troubleshoot the Platform).",
    "short_term_use": "Short-term, transient use, provided that the personal information is not disclosed to another third party and is not used to build a profile about a consumer or otherwise alter an individual consumer's experience outside the current interaction.",
    "undertaking_research_reporting": "Undertaking internal research and reporting;",
    "technological_development": "For technological development and demonstration, including to improve the content and features of the Platform or develop or deliver new products or services;",
    "contacting_users": "Contacting you with information or surveys regarding our Platform;",
    "processing_inquiries": "Processing and responding to your inquiries or to request your feedback;",
    "personalizing_content": "Personalizing the content that you see on the Platform (see How Does CANDID Personalize Content to My Interests?” section below);",
    "enforcing_legal_terms": "Enforcing the legal terms that govern your use of the Platform.",
    "aggregated_deidentified_information": "In addition to the use of your information to process your loan or other financial services transaction, we may also aggregate and/or de-identify the information we collect. We may use aggregated and/or de-identified information for improving the Platform and developing business analytics, and may also share such information and analytics with third parties.",
    "personalizing_content_title": "How Does CANDID Personalize Content to My Interests?",
    "online_email_analytics_title": "Online and Email Analytics:",
    "online_email_analytics_content": "We may use third-party web analytics services on the Platform, such as those of Google Analytics. These service providers use the sort of technology previously described in the Cookies and Other Electronic Technologies” section to collect information (including your IP address) to help us analyze how users use the Platform, including by noting the third-party website from which you arrive, providing certain features to you, improving and developing the Platform, monitoring and analyzing use of the Platform, aiding our technical administration, assisting in our troubleshooting and customer support efforts, and verifying that users have the authorization needed for us to process their requests. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser Add-on by clicking",
    "online_email_analytics_content_1": 'here',
    "online_email_analytics_content_2": '. If you get a new computer, install a new browser, erase or otherwise alter your browser’s cookie file (including upgrading certain browsers), you may clear the Google Analytics opt-out cookies, and you will need to re-visit the relevant opt-out page. If you receive email communication from us, we may use certain tools, such as clear GIFs, to capture data such as when you open our message or click on any links or banners our email contains.',
    "do_not_track_notice_title": "Notice Concerning Do Not Track Signals:",
    "do_not_track_notice_content": "Do Not Track (DNT”) is a privacy preference that users can set in certain web browsers. We are committed to providing you with meaningful choices about the information collected on the Platform for third-party purposes, and that is why we provide choices provided by Google Analytics. However, we do not recognize or respond to browser-initiated DNT signals, as the internet industry is currently still working toward defining exactly what DNT” means, what it means to comply with DNT, and a common approach to responding to DNT. You can learn more about DNT by",
    "do_not_track_notice_content_1": "clicking here",
    "how_candid_shares_information_title": "4. How Will CANDID Share My Information?",
    "information_sharing_notice": "We may share all of the categories of information identified above in Section 2 with the following categories of third parties as described in this section. We base the information in this disclosure on our data sharing practices over the previous 12 months until present time.",
    "financial_services_providers_title": "Financial Services Providers",
    "financial_services_providers_content": ". We collect information for Financial Services Providers that are evaluating, processing, or funding loan applications or other financial services applications. We may share the information you provide with, and/or make that information available to Financial Services Providers, their successors-in-interest, and other relevant third parties that the Financial Services Provider has authorized to access the information in order to facilitate the loan process or other financial services transaction, and provide related services and products to you. If you are a customer of such a Financial Services Provider and have questions about its privacy practices or you no longer want to be contacted by the Financial Services Provider, please contact the Financial Services Provider directly. The Financial Services Provider’s privacy policy, and not this Policy will apply to the Financial Services Provider’s use of your information, and to the sharing and use of your information to/by others authorized by the Financial Services Provider to obtain your information. CANDID is not responsible for the privacy practices of the Financial Services Providers.",
    "affiliates_subsidiaries_title": "Affiliates and Subsidiaries of CANDID",
    "affiliates_subsidiaries_content": ". We may share information we collect with other members of the CANDID family of companies (e.g., CANDID Insurance) on behalf of your Financial Services Provider to deliver products and services to you, ensure a consistent level of service across our products and services, and enhance our products, services, and your customer experience.",
    "service_providers_title": "Service Providers",
    "service_providers_content": ". We may provide access to or share your information with select third parties who perform services on our behalf to facilitate the operation and functionality of the Platform. For example, we may use companies that assist with billing, customer support, fulfillment, data storage, analysis and processing, and legal services. We may also provide access to or share information with third parties as necessary to effect, administer, or enforce a transaction that you request or authorize.",
    "data_access_providers_title": "Data Access Providers and Other Third Parties",
    "data_access_providers_content": ". We may use data access providers, including",
    "data_access_providers_content_1" :"Plaid Inc.",
    "data_access_providers_content_2" :"Finicity Corporation",
    "data_access_providers_content_3" :", and",
    "data_access_providers_content_4" :"Yodlee, Inc.",
    "data_access_providers_content_5" :", to assist in obtaining information from your financial institution for the purpose of completing the application (e.g., account balance information, relevant financial statements and summaries, and other similar information). If you choose to provide your Third-Party Account Credentials to link with your third-party financial accounts, you grant us and our data access providers the right to retrieve information from the relevant third party on your behalf and transmit such information to the Financial Services Providers. The collection, use, transmission, and storage of this information by our data access providers is governed by the privacy policy of the applicable data access provider. If following the initial retrieval of information from your third-party financial account, you no longer want us or our data access providers to obtain information from such accounts in connection with continued provision of services to you, please see the What Choices Do I Have” section below. CANDID may also share your information with partners of CANDID or the Financial Services Provider (e.g., pricing engines, document generation providers).",
    "consent_title": "Consent",
    "consent_content": ". On behalf of your Financial Services Provider we may also share your information with other third parties after obtaining your consent.",
    "protection_of_candid_title": "Protection of CANDID and Others",
    "protection_of_candid_content": ". By using the Platform, you acknowledge, consent, and agree that we may access, preserve, and disclose your information, including but not limited to any user content, if required to do so by law or in a good faith belief that such access, preservation, or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce our Terms of Service, this Policy, or other contracts with you, including investigation of potential violations thereof; (c) respond to claims that any content violates the rights of third parties; (d) respond to your requests for customer service; and/or (e) protect the rights, property, or personal safety of CANDID, its agents and affiliates, and/or its users and/or the public. This includes exchanging information with other companies and organizations for fraud protection, spam/malware prevention, and similar purposes.",
    "business_transfers_title": "Business Transfers",
    "business_transfers_content": ". As we continue to develop our business, we may buy or merge with other companies. In such transactions (including in contemplation of such transactions, e.g., due diligence), user information may be among the transferred assets. If a portion or all of CANDID’s assets are sold or transferred to a third party pursuant to a corporate purchase, sale, or merger, customer information may be one of the transferred business assets.",
    "data_security_title": "5. How Does CANDID Secure My Data?",
    "data_security_content": "We have implemented administrative, technical, and physical security measures via third party vendors to protect against the loss, misuse, and/or alteration of your information. These safeguards vary based on the sensitivity of the information that we collect and store. However, we cannot and do not guarantee that these measures will prevent every unauthorized attempt to access, use, or disclose your information because despite our efforts, no internet and/or other electronic transmissions can be completely secure.",
    "data_security_content_1": "and change your password. We are not responsible if someone else accesses your Platform account through information they have obtained from you.",
    "password_recommendation_content": "We recommend that you change your password periodically. You are responsible for maintaining the security of your Platform account username and password. If you believe that your Platform account username and/or password have been stolen or been made known to others, it is your responsibility to immediately contact us at",
    "password_recommendation_content_1": "your password. We are not responsible if someone else accesses your Platform account through information they have obtained from you.",
    "choices_title": "6. What Choices Do I Have?",
    "updating_deleting_info_title": "Updating and Deleting Your Information",
    "updating_deleting_info_content": ". You may be able to add, update, or delete certain information through the Platform. When you update information, however, we may maintain a copy of the unrevised information in our records for legal compliance purposes. Any information that cannot be updated or deleted through the Platform will require you to contact the Financial Services Provider for further assistance. Additionally, to the extent applicable law affords you rights to your personal information, such as access and deletion rights, please reach out to your Financial Services Provider to inquire about your data rights. We retain the information we process for as long as needed to provide services to the Financial Services Provider, including providing information to successors-in-interest throughout the term of the transaction. We may retain some of the information for reasons including, but not limited to, compliance with applicable banking and lending regulations, resolving disputes, and enforcing our agreements. We may also continue to use any aggregated or de-identified information but not in a manner that would identify you personally.",
    "account_access_title": "Account Access for Providing Financial Services",
    "account_access_content": ". If you no longer want us or our data access providers to obtain information from your financial accounts on behalf of your Financial Services Provider, please email us at info@candid.inc.",
    "california_residents_title": "7. California Residents.",
    "california_policy_title": "This section of the Policy applies solely to those individuals who reside in the State of California (\"consumers\" or \"you\"). We are providing this policy in compliance with the California Consumer Privacy Act of 2018 (\"CCPA\") and other applicable California privacy laws. Any terms used in this Notice that are not defined in the Notice itself have the same definition as used in the CCPA and its implementing regulations.",
    "right_to_know_title": "Right to Know",
    "right_to_know_content": "California law provides California consumers with the right to request that certain covered businesses provide you (i) the categories of personal information we collect, use, disclose or sell about you; (ii) the categories of sources of such information; (iii) the business or commercial purpose for collecting or selling your personal information (as applicable); (iv) the categories of personal information we sell or have sold or disclosed for a business purpose about the consumer and the categories of third parties to whom we’ve disclosed your personal information and, (v) specific pieces of personal information that a business has collected about the consumer. This 'Right to Know' is subject to certain exceptions. Furthermore, CANDID is not required to respond to requests for the Right to Know because we are a Service Provider to your Financial Services Provider. However, in our Service Provider capacity, we maintain and have identified the items listed in this paragraph in our Privacy Policy for your reference. To request the specific pieces of personal information CANDID collects about you on the Platform, please reach out to your Financial Services Provider.",
    "right_to_delete_title": "Right to Delete",
    "right_to_delete_content": "California law provides California consumers with the right to request that certain covered businesses delete personal information that they have collected from you. This 'Right to Delete' is subject to certain exceptions. CANDID will not respond to requests pursuant to this right if made directly by a consumer because we are a Service Provider to your Financial Services Provider. To request deletion of specific pieces of personal information CANDID collects about you on the Platform, please reach out to your Financial Services Provider. Please note, we need certain types of information so that we can provide the services to you. If you request deletion of your information through your Financial Services Provider and we are required to delete your information, you may no longer be able to access or use the services we provide.",
    "authorized_agents_title": "Authorized Agents",
    "authorized_agents_content": "When a consumer uses an authorized agent to submit a request to know or a request to delete, a business may require that the consumer do the following (this does not apply when a consumer has provided the authorized agent with power of attorney pursuant to Probate Code sections 4000 to 4465):",
    "authorized_agents_content_1":"Provide the authorized agent signed permission to do so.",
    "authorized_agents_content_2":"Verify their own identity directly with the business.",
    "authorized_agents_content_3":"Directly confirm with the business that they provided the authorized agent permission to submit the request.",
    "authorized_agents_content_4":"A business may deny a request from an authorized agent that does not submit proof that they have been authorized by the consumer to act on their behalf.",
    "authorized_agents_content_5":"To make an authorized request on behalf of another pursuant to consumer rights provided by California Law, please reach out to your Financial Services Provider for instructions.",
    "additional_rights_title": "Additional Rights",
    "additional_rights_content": "Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to ask certain covered businesses for a notice (a) identifying the categories of personal information (as defined by California law) that are shared with third parties for their own marketing purposes and (b) providing contact information for such third parties.",
    "additional_rights_content_1":" However, as disclosed here in our Privacy Policy, CANDID does not share such personal information for these purposes.",
    "financial_incentives_title": "Financial Incentives",
    "financial_incentives_content": "We do not offer financial incentives for the collection of your data. If this changes, we will notify you prior to doing so, and we will obtain your consent to opt-in to this program before enrolling you.",
    "non_discrimination_rights_title": "Non-discrimination Rights",
    "non_discrimination_rights_content": "You also have the right to not be discriminated against for exercising certain of your rights under the CCPA. If you exercise your rights under the CCPA, we will not:",
    "non_discrimination_rights_content_1":"Deny you goods or services.",
    "non_discrimination_rights_content_2":"Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.",
    "non_discrimination_rights_content_3":"Provide you a different level or quality of goods or services.",
    "non_discrimination_rights_content_4":"Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.",
    "privacy_laws_rights_title": "How other data privacy laws may affect your ability to request access to, or delete, your data",
    "privacy_laws_rights_content": "Please note that your rights and choices regarding your personal information are often subject to other laws. Personal information collected pursuant to other laws, such as information governed by the Fair Credit Reporting Act, the Gramm-Leach-Bliley Act, and the California Financial Information Privacy Act, may be exempt from requests to access or delete data.",
    "technology_service_provider_title": "We are a technology Service Provider",
    "technology_service_provider_content": "We are a Service Provider as defined by the CCPA and we process your information on behalf of banks, mortgage companies, and other lenders (Financial Services Providers). To the extent California law allows you to make certain requests including access to personal information or requests to delete Personal Information, you must make these requests directly to your Financial Services Provider.",
    "contact_us_title": "Contact us",
    "contact_us_content": "If you have any questions regarding your rights under California law, you may reach out to us at info@candid.inc.",
    "nevada_residents_title": "8. Nevada Residents",
    "nevada_residents_content": 'Under Nevada law, certain Nevada consumers may opt out of the sale of personally identifiable information" for monetary consideration to a person for that person to license or sell such information to additional persons. Personally identifiable information" includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online. We do not engage in such activity; however, if you are a Nevada resident who has purchased or leased goods or services from us, you may submit a request to opt out of any potential future sales under Nevada law by emailing info@candid.inc. Please note we will take reasonable steps to verify your identity and the authenticity of the request. Once verified, we will maintain your request in the event our practices change.',
    "third_party_links_title": "9. What Is CANDID’s Responsibility for Third-Party Links on the Platform?",
    "third_party_links_content": 'The Platform may contain links to or frame" third-party websites, applications, and other services available to support Platform operations and functionality. Please be aware that we are not responsible for the privacy practices of such other sites and services. We encourage our users to be aware when they access third-party websites and/or leave the Platform and to read the privacy statements of each and every site they visit that collects their information.',
    "children_policy_title": "10. What Is CANDID’s Policy on Children?",
    "children_policy_content": 'We do not knowingly collect or solicit personal information (as defined by the Children’s Online Privacy Protection Act") from anyone under the age of 13. If you are under 13, please do not attempt to register for the Platform or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact info@candid.inc.',
    "retention_of_information_title": "11. Retention of Your Information",
    "retention_of_information_content": "We keep your information in identifiable form for no longer than necessary for the purposes for which it is processed. The length of time for which we retain information depends on the purposes for which we collected and use it and/or as required to comply with applicable laws.",
    "policy_changes_title": "12. Will CANDID Change This Policy?",
    "policy_changes_content": "We reserve the right to change this Policy at any time to reflect changes in the law, our data collection and use practices, the features of the Platform, or advances in technology. Please check this page periodically for changes. Your continued use of the Platform following the posting of changes to this Policy will mean you accept those changes.",
    "questions_about_policy_title": "13. What If I Have Questions About This Privacy Policy?",
    "questions_about_policy_content": "If you have any questions or concerns regarding our Privacy Policy, please send us a detailed message to info@candid.inc and we will try to resolve your concerns.",
    "close_button": "Close"
}
 export default privacyObject