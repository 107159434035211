<template>
  <div class="wrapper">
    <div class="sign-in-up forgot-password" id="change-email">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <div class="lock-icon">
            <span>
              <img src="../assets/images/lock-red-icon.svg" alt="lock-icon" />
            </span>
          </div>
          <h2>{{ translatedData?.pageTitle }}</h2>
          <p>
            {{ translatedData?.pageDescription }}
          </p>
          <form @submit.prevent="changeEmail">
            <div class="form-group">
              <label class="form-label"
                >{{ translatedData?.newEmailLabel }}</label
              >
              <input
                v-model.trim="$v.email.$model"
                type="email"
                inputmode="email"
                class="form-control"
                :class="$v.email.$error ? 'is-invalid' : ''"
              />
              <small class="invalid-feedback" v-if="!$v.email.required"
                >{{ translatedData?.emailRequiredError }}</small
              >
              <small class="invalid-feedback" v-else-if="!$v.email.email"
                >{{ translatedData?.emailInvalidError }}</small
              >
            </div>
            <div class="button w-100">
              <button
                type="button"
                class="btn w-100"
                :class="!$v.$invalid ? 'btn-red' : ''"
                :disabled="$v.$invalid"
                @click="changeEmail"
              >
                <span
                  >{{ translatedData?.changeEmailButtonText }}</span
                >
              </button>
            </div>
            <div class="button w-100 mt-3 mb-3">
              <router-link to @click.native="$router.go(-1)" class="w-100">
                <button type="button" class="btn btn-cancel w-100">
                  <span>{{ translatedData?.cancelButtonText }}</span>
                </button>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const emailChangeJson = {
  pageTitle: "Create new Email",
  pageDescription: "Enter a new email for your account",
  newEmailLabel: "New Email",
  emailRequiredError: "Email field is required.",
  emailInvalidError: "Please enter a valid email address.",
  changeEmailButtonText: "Change Email",
  cancelButtonText: "Cancel",
};
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { translationMixin } from "../mixins/translationMixin";
export default {
  name: "Change-Email",
  mixins: [translationMixin],
  data() {
    return {
      email: "",
      signInBg: "",
      data: emailChangeJson,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  watch: {
    signInBg(newVal) {
      if (newVal) {
        let theBox = document.getElementById("change-email");

        theBox.style.backgroundImage = `url(${newVal})`;
        theBox.style.backgroundSize = "cover";
      }
    },
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;
    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters(["getOrgId", "getUserId", "getAssets", "getProspectEmail"]),
  },
  methods: {
    ...mapActions(["fetchLoaderStatus"]),

    async changeEmail() {
      if (!this.getUserId) {
        this.err("You must login to change the email.");
        return;
      }

      this.fetchLoaderStatus(true);

      let payLoad = {
        password: "",
        email: this.email,
        prospectId: this.getUserId + "&&" + this.getProspectEmail,
        orgId: this.getOrgId,
      };

      await this.$http
        .post("/account/UpdateUser/", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success("Email updated successfully");

            this.email = "";
            this.$v.$reset();

            setTimeout(() => {
              this.$router.go(-1);
            }, 1500);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchLoaderStatus(false);
    },
  },
};
</script>
