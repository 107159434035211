import { translateTexts } from "../translate-service-new"; // Import your translation function
import { mapGetters } from "vuex";

export const addressTranslationMixin = {
  computed: {
    ...mapGetters(["getAppLanguage"]),
  },
  methods: {
    translateTexts, // Assuming you already have this method to handle translation

    async translateAddressQuestions(resp, changeLanguage = false) {
      // Ensure resp is valid
      if (resp && Array.isArray(resp) && resp.length > 0) {
        try {
          const targetLanguage = this.getAppLanguage; // Define target language based on app's language setting
          const fieldsToTranslate = ["questionAnswer", "questionText"]; 

          // Separate arrays for questionAnswer and questionText translations
          const questionsToTranslateAnswers = [];
          const questionsToTranslateTexts = [];
          const questionMapping = []; // To store reference for questionAnswer
          const questionTextMapping = []; // To store reference for questionText

          // Iterate through sections and collect the questionAnswer and questionText fields for batch translation
          for (const section of resp) {
            if (section?.addressDetails && Array.isArray(section?.addressDetails)) {
              for (const addressDetail of section.addressDetails) {
                if (addressDetail?.addressData && Array.isArray(addressDetail?.addressData)) {
                  for (const question of addressDetail.addressData) {
                    // Push questionAnswer to questionsToTranslateAnswers
                    if (question?.questionAnswer) {
                      questionsToTranslateAnswers.push(question.questionAnswer);
                      questionMapping.push(question); // Store reference for questionAnswer
                    }
                    // Push questionText to questionsToTranslateTexts
                    if (question?.questionText) {
                      questionsToTranslateTexts.push(question.questionText);
                      questionTextMapping.push(question); // Store reference for questionText
                    }
                  }
                }
              }
            } else if (section?.addressData && Array.isArray(section.addressData)) {
              // Handle cases where addressDetails is missing but addressData exists directly in the section
              for (const question of section?.addressData) {
                // Push questionAnswer to questionsToTranslateAnswers
                if (question?.questionAnswer) {
                  questionsToTranslateAnswers.push(question.questionAnswer);
                  questionMapping.push(question); // Store reference for questionAnswer
                }

                // Push questionText to questionsToTranslateTexts
                if (question?.questionText) {
                  questionsToTranslateTexts.push(question.questionText);
                  questionTextMapping.push(question); // Store reference for questionText
                }
              }
            }
          }

          // Perform batch translation for both questionAnswer and questionText fields
          let translatedAnswers = [];

          if (questionsToTranslateAnswers.length > 0) {
            const translatedAnswerBatch = await this.translateTexts(
              questionsToTranslateAnswers,
              fieldsToTranslate,
              targetLanguage
            );
            translatedAnswers = [
              ...translatedAnswers,
              ...translatedAnswerBatch,
            ]; // Append to the main array
          }

          if (questionsToTranslateTexts.length > 0) {
            const translatedTextBatch = await this.translateTexts(
              questionsToTranslateTexts,
              fieldsToTranslate,
              targetLanguage
            );
            translatedAnswers = [...translatedAnswers, ...translatedTextBatch]; // Append to the main array
          }

          // Map the translated answers back to the original questions
          translatedAnswers.forEach((translatedAnswer, index) => {
            // Handle questionAnswer translation
            if (index < questionMapping.length) {
              const question = questionMapping[index];
              if (
                question &&
                (!question.translatedQuestionAnswer || changeLanguage)
              ) {
                question.translatedQuestionAnswer = translatedAnswer;
              }
            }
            // Handle questionText translation
            else if (
              index - questionMapping.length <
              questionTextMapping.length
            ) {
              const questionText =
                questionTextMapping[index - questionMapping.length];
              if (
                questionText &&
                (!questionText.translatedQuestionText || changeLanguage)
              ) {
                questionText.translatedQuestionText = translatedAnswer;
              }
            }
          });

          // Return the modified response after translation
          return resp;
        } catch (error) {
          console.error("Error in translation process: ", error);
          return resp; // Return the original response if an error occurs
        }
      }

      // If response is invalid or empty, return it as-is
      console.warn("Invalid or empty response provided.");
      return resp;
    },
  },
};
