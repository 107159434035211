<template>
  <div>
    <address-fields
      v-if="showFields"
      :question="question"
      :currentaq="currentaq"
      :previousaq="previousaq"
      :addressId="currentAddressId"
      :userAddresses="userAddresses"
    />

    <form class="property" v-if="!showFields">
      <div class="question">
        <h2 v-if="getQueId">{{ getUserNameWithQueText }}</h2>
        <h2 v-else>{{ questionGName }}</h2>
        <p class="mt-3" v-show="question.questionDescription">
          {{ questionDesc }}
        </p>
        <p class="personal-info mt-3" v-show="question.subGroupName">
          {{ questionSubGName }}
        </p>
      </div>
      <div
        v-show="
          getActiveSection === 'Co-Applicant Info' && !userAddresses.length
        "
        class="badge bg-dynamic mb-2 cursor-pointer"
        @click="copyApplicantsAddress"
      >
        {{ translatedData?.copy }} {{ getApplicantName }}'{{
          translatedData?.adressHistory
        }}
      </div>
      <ul>
        <li v-for="(address, index) in translatedUserAddresses" :key="index">
          <div class="title">
            <h2>{{ formTheAddress(address.addressData) }}</h2>
            <span
              class="text-red"
              v-if="
                index === 0 &&
                (getActiveSection === 'Personal Information' ||
                  getActiveSection === 'Co-Applicant Info' ||
                  getActiveSection === 'Real Estate Owned')
              "
              ><strong>{{ translatedData?.currentAddress }}</strong></span
            >
          </div>
          <div
            v-show="showSubjectProperty(address)"
            class="badge bg-success mb-2"
          >
            {{ translatedData?.subjectProperty }}
          </div>

          <p v-if="getActiveSection === 'Real Estate Owned'">
            <span v-if="reOrderQueForREO(address.addressData)"
              >{{
                address.addressData[reOrderQueForREO(address.addressData)]
                  .questionText
              }}:
              <strong>{{
                address.addressData[reOrderQueForREO(address.addressData)]
                  .questionAnswer
              }}</strong></span
            >
          </p>
          <p v-for="(subs, i) in address.addressData" :key="i">
            <span
              v-if="
                subs.questionType === 'Picklist' &&
                subs.questionFieldMapping !==
                  'currently_making_monthly_payments__c' &&
                subs.questionFieldMapping !==
                  'candidoem__currently_making_monthly_payments__c' &&
                subs.questionFieldMapping !== 'occupancy__c' &&
                subs.questionFieldMapping !== 'candidoem__occupancy__c'
              "
            >
              {{ subs?.translatedQuestionText }}:
              <strong>{{ subs?.questionAnswer }}</strong></span
            >
            <span
              v-else-if="
                (subs.questionFieldMapping === 'move_in_date__c' ||
                  subs.questionFieldMapping === 'candidoem__move_in_date__c') &&
                subs.questionAnswer
              "
              >{{ subs?.translatedQuestionText }}:
              <strong>{{
                subs?.questionAnswer | formatDate
              }}</strong></span
            >
            <span
              v-else-if="
                (subs.questionFieldMapping === 'monthly_rent__c' ||
                  subs.questionFieldMapping === 'candidoem__monthly_rent__c') &&
                subs.questionAnswer &&
                index === 0 &&
                getActiveSection === 'Personal Information'
              "
              >{{ subs?.translatedQuestionText }}:
              <strong>{{
                Number(subs.questionAnswer).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }}</strong></span
            >
          </p>
          <div class="edit-delete mt-3">
            <a
              class="btn-icon edit cursor-pointer"
              data-bs-toggle="modal"
              :data-bs-target="`#deleteModal${address.addressId}`"
            >
              <i class="fa fa-trash"></i>&nbsp;&nbsp;
              {{ translatedData?.delete }}</a
            >&nbsp;&nbsp;&nbsp;
            <a
              @click="
                showFields = true;
                currentAddressId = address.addressId;
              "
              class="btn-icon edit cursor-pointer"
            >
              <i class="fa fa-pencil-alt"></i>&nbsp;&nbsp;
              {{ translatedData?.edit }}
            </a>
          </div>

          <!----Delete Modal start---->
          <div
            class="modal fade"
            :id="`deleteModal${address.addressId}`"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title text-red" id="staticBackdropLabel">
                    {{ translatedData?.warning }}
                  </h3>
                </div>
                <div class="modal-body">
                  {{ translatedData?.isDelete }}
                  <p class="text-primary mt-3">
                    {{ formTheAddress(address.addressData) }}
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline"
                    data-bs-dismiss="modal"
                  >
                    {{ translatedData?.close }}
                  </button>
                  <button
                    type="button"
                    @click="deleteTheAddress(address)"
                    class="btn btn-red"
                    data-bs-dismiss="modal"
                  >
                    <span>{{ translatedData?.delete }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!----Delete Modal end---->
        </li>

        <li v-if="showAnotherPropertyButton">
          <div class="title">
            <button
              type="button"
              @click="
                showFields = true;
                currentAddressId = '';
              "
              class="d-flex align-items-center"
            >
              <div class="plus-icon">
                <i class="fa fa-plus"></i>
              </div>
              <h2
                class="mb-0"
                v-if="getActiveSection === 'Personal Information'"
              >
                Add a previous address
              </h2>
              <h2 class="mb-0" v-else>Add another property</h2>
            </button>
          </div>
        </li>
      </ul>

      <div class="button mt-4 pt-3">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>{{ translatedData?.previous }}</span>
        </button>
        <button type="button" class="btn btn-red" @click="nextQuestion">
          <span>{{ translatedData?.continue }}</span>
        </button>
      </div>
      <a
        v-if="question.questionLink"
        :data-bs-toggle="
          question.questionLink && question.questionLinkDescription
            ? 'modal'
            : ''
        "
        data-bs-target="#infoModal"
        class="text-underline"
        >{{ translatedData?.questionLink }}</a
      >
      <info-modal
        v-if="question.questionLink && question.questionLinkDescription"
        :title="translatedData?.questionLink"
        :description="translatedData?.questionLinkDescription"
        :close="translatedData?.close"
      />
    </form>
  </div>
</template>

<script>
import { eventBus } from "./../../event-bus";
import commonFunctions from "./../../mixins/commonfunctions";
import { translationMixin } from "../../mixins/translationMixin";
import { addressTranslationMixin } from "../../mixins/addressMxin";
import AddressFields from "./AddressFields.vue";
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

export default {
  name: "View-Addresses",
  components: { AddressFields },
  mixins: [commonFunctions, addressTranslationMixin, translationMixin],
  data() {
    return {
      showFields: false,
      currentAddressId: "",
      theQuestions: [],
      userAddresses: [],
      translatedUserAddresses: [],
      data: {
        copy: "Copy",
        adressHistory: "s address history",
        currentAddress: "Your current residence",
        subjectProperty: "Subject Property",
        warning: "Warning!!!",
        isDelete: "Are you sure you want to delete this property?",
        close: "Close",
        delete: "Delete",
        continue: "Continue",
        previous: "Previous",
        edit: "Edit",
        questionLink: this.question?.questionLink,
        questionLinkDescription: this.question?.questionLinkDescription,
      },
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  watch: {
    getAllAddresses: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let addresses = newVal.find(
          (v) => v.sectionName === this.getActiveSection
        );

        if (addresses) this.userAddresses = addresses.addressDetails;
        else this.userAddresses = [];
      },
    },

    userAddresses: {
      deep: true,
      immediate: true,
      async handler(newVal) {
        this.translatedUserAddresses = await this.translateAddressQuestions(newVal, false)
        if (newVal.length) this.showFields = false;
        if (!newVal.length) this.showFields = true;
      },
    },
    getAppLanguage: {
      async handler() {
        const translatedUserEmploymentsResult = await this.translateAddressQuestions(this.userAddresses, true);
        this.translatedUserEmployments = translatedUserEmploymentsResult
      },
    },
  },
  computed: {
    ...mapGetters(["getAppLanguage"]),
    showAnotherPropertyButton() {
      if (
        this.userAddresses.length &&
        (this.getActiveSection === "Personal Information" ||
          (this.checkCoApplicant &&
            this.getActiveSection === "Co-Applicant Info"))
      ) {
        let compareDate = moment().subtract(2, "years").format("M/D/YYYY");

        let check = this.userAddresses.some((v) => {
          let get_date = v.addressData.find(
            (x) =>
              x.questionFieldMapping === "move_in_date__c" ||
              x.questionFieldMapping === "candidoem__move_in_date__c"
          );

          if (get_date?.questionAnswer)
            return moment(get_date.questionAnswer).isBefore(compareDate);
        });

        return !check;
      }

      return true;
    },

    checkCoApplicant() {
      return this.getQuestions.some(
        (v) =>
          (v.questionFieldMapping === "is_joint_applicant_added__c" ||
            v.questionFieldMapping ===
              "candidoem__is_joint_applicant_added__c") &&
          v.questionAnswer === "Yes"
      );
    },

    getQueId() {
      let string;

      if (
        this.checkCoApplicant &&
        this.getActiveSection === "Real Estate Owned"
      ) {
        string = this.question.coGroupName || this.question.groupName;
      } else {
        string = this.question.groupName;
      }

      let question_id;

      if (string) {
        question_id = string.substring(
          string.indexOf("{") + 1,
          string.lastIndexOf("}")
        );
      }

      return question_id || false;
    },

    getUserNameWithQueText() {
      if (this.getQueId) {
        let string;

        if (
          this.checkCoApplicant &&
          this.getActiveSection === "Real Estate Owned"
        ) {
          string = this.question.coGroupName || this.question.groupName;
        } else {
          string = this.question.groupName;
        }

        let question_id = string.substring(
          string.indexOf("{"),
          string.lastIndexOf("}") + 1
        );

        let get_name = this.getQuestions.find(
          (v) => v.questionId === this.getQueId
        );

        let que_text = get_name
          ? string.replace(question_id, get_name.questionAnswer)
          : string;

        return que_text || "";
      }

      return "";
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
    this.mergeTheQuestions();

    eventBus.$on("show-fields", (data) => {
      this.showFields = data;
    });
  },
  mounted() {
    this.checkTheDependancy(this.question);

    let addresses = this.getAllAddresses.find(
      (v) => v.sectionName === this.getActiveSection
    );

    if (addresses) this.userAddresses = addresses.addressDetails;

    if (!this.userAddresses.length) this.showFields = true;
  },
  methods: {
    reOrderQueForREO(address) {
      let theIndex = address.findIndex(
        (z) =>
          z.questionFieldMapping === "occupancy__c" ||
          z.questionFieldMapping === "candidoem__occupancy__c"
      );

      return theIndex >= 0 ? theIndex : null;
    },

    showSubjectProperty(address) {
      let check = false;

      if (this.getAppFlow === "Refinance") {
        let isSubjectProperty = address.addressData.find(
          (r) =>
            r.questionFieldMapping ===
              "is_this_the_home_you_wish_to_refinance__c" ||
            r.questionFieldMapping ===
              "candidoem__is_this_the_home_you_wish_to_refinance__c"
        );

        if (isSubjectProperty?.questionAnswer === "Yes") check = true;
      }

      return check;
    },

    formTheAddress(address) {
      let addr = cloneDeep(address);

      let x = "";
      addr.forEach((v) => {
        if (v.questionType === "Text" && v.questionAnswer) {
          x += ", " + v.questionAnswer;
        }
      });

      return x.substring(1);
    },

    async deleteTheAddress(address) {
      this.fetchLoaderStatus(true);

      await this.$http
        .get(
          "/address/deleteAddress?addressId=" +
            address.addressId +
            "&flowName=" +
            this.getAppFlow +
            "&prospectId=" +
            this.getUserId +
            "&sectionName=" +
            this.getActiveSection +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.currentAddressId = "";
            await this.getAddresses();
            this.success("Property record deleted successfully");
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchLoaderStatus(false);
    },

    mergeTheQuestions() {
      let theFields = [];

      for (
        let i = this.getActiveQuestion - 1;
        i < this.getQuestions.length;
        i++
      ) {
        if (
          this.getQuestions[i].groupName === "" ||
          this.getQuestions[i].groupName !== this.question.groupName
        ) {
          break;
        }
        theFields.push(this.getQuestions[i]);
      }

      this.theQuestions = theFields;
    },

    nextQuestion() {
      if (
        this.getAppFlow === "Refinance" &&
        this.getActiveSection === "Real Estate Owned" &&
        !this.getSubjectPropertyStatus
      ) {
        this.info("Please add or indicate property you wish to refinance.");
        return;
      }

      if (
        (this.getActiveSection === "Personal Information" ||
          (this.checkCoApplicant &&
            this.getActiveSection === "Co-Applicant Info")) &&
        this.showAnotherPropertyButton
      ) {
        this.info("Please provide minimum required 24-month housing history.");
        return;
      }

      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      )
        this.$router.push("/submit-application");

      if (this.getActiveQuestion <= this.getQuestions.length) {
        this.fetchActiveQuestion(this.theQuestions.at(-1).questionIndex + 1);
      }
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.theQuestions[0].questionIndex - 1);
    },

    async copyApplicantsAddress() {
      let addresses = cloneDeep(this.getAllAddresses);

      if (addresses.length) {
        this.fetchLoaderStatus(true);

        let new_addresses;

        let pi_addresses = addresses.find(
          (k) => k.sectionName === "Personal Information"
        );

        if (pi_addresses) {
          new_addresses = pi_addresses.addressDetails.map((v) => {
            v.addressId = null;

            v.addressData.forEach((x) => {
              x.sectionName = "Co-Applicant Info";
              if (
                x.questionFieldMapping === "move_in_date__c" ||
                x.questionFieldMapping === "candidoem__move_in_date__c"
              ) {
                x.questionAnswer = moment(x.questionAnswer).format(
                  "YYYY-MM-DD"
                );
              }

              if (
                this.getAppFlow === "Refinance" &&
                (x.questionFieldMapping ===
                  "is_this_the_home_you_wish_to_refinance__c" ||
                  x.questionFieldMapping ===
                    "candidoem__is_this_the_home_you_wish_to_refinance__c")
              ) {
                x.questionAnswer = "No";
              }
            });

            return v;
          });
        }

        let payLoad = {
          prospectId: this.getUserId,
          flowName: this.getAppFlow,
          addressDetails: new_addresses,
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/address/createAndUpdateAddress/", payLoad)
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") await this.getAddresses();
            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => console.log(error));

        this.fetchLoaderStatus(false);
      } else {
        this.info("No addresses to copy");
      }
    },

    async getAddresses() {
      await this.$http
        .get(
          "/address/GetAllAddress/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchAllAddresses(resp.addressData || []);

            if (this.getAppFlow === "Refinance") {
              let is_subject_property = resp.addressData.some(
                (c) => c.hasSubjectProperty
              );

              this.fetchSubjectPropertyStatus(is_subject_property);
            }

            if (this.getAppFlow === "Buying" && this.getSubjectPropertyStatus)
              this.fetchSubjectPropertyStatus(false);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
