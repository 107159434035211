<template>
  <div class="wrapper">
    <div class="sign-in-up forgot-password" id="change-password">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <div class="lock-icon">
            <span>
              <img src="../assets/images/lock-red-icon.svg" alt="lock-icon" />
            </span>
          </div>
          <h2>{{ translatedData?.pageTitle }}</h2>
          <p>{{ translatedData?.pageDescription }}</p>
          <form @submit.prevent="changePassword" @keyup.enter="changePassword">
            <div class="form-group">
              <label class="form-label">{{ translatedData?.newPasswordLabel }}</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.password.$model"
                  :type="showPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.password.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showPass = !showPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i
                      :class="showPass ? 'far fa-eye' : 'fas fa-eye-slash'"
                    ></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.password.$error">
                <small
                  v-if="!$v.password.required"
                  class="invalid-feedback d-block"
                  >{{ translatedData?.passwordRequiredError }}</small
                >
                <small
                  v-else-if="!$v.password.maxLength"
                  class="d-block invalid-feedback"
                  >{{ translatedData?.passwordMaxLengthError1 }} {{ $v.password.$params.maxLength.max }} {{ translatedData?.passwordMaxLengthError2 }}</small
                >
              </div>
            </div>
            <div class="form-group mb-4 pb-3">
              <label class="form-label">{{ translatedData?.confirmPasswordLabel }}</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.confirmPassword.$model"
                  :type="showCoPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.confirmPassword.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showCoPass = !showCoPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i
                      :class="showCoPass ? 'far fa-eye' : 'fas fa-eye-slash'"
                    ></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.confirmPassword.$error">
                <small
                  v-if="!$v.confirmPassword.required"
                  class="d-block invalid-feedback"
                  >{{ translatedData?.confirmPasswordRequiredError }}</small
                >
                <small
                  v-else-if="!$v.confirmPassword.sameAs"
                  class="d-block invalid-feedback"
                  >{{ translatedData?.passwordMismatchError }}</small
                >
              </div>
            </div>
            <div class="button w-100">
              <button
                type="button"
                class="btn w-100"
                :class="!$v.$invalid ? 'btn-red' : ''"
                :disabled="$v.$invalid"
                @click="changePassword"
              >
                <span>{{ translatedData?.changePasswordButtonText }}</span>
              </button>
            </div>
            <div class="button w-100 mt-3 mb-3">
              <router-link to @click.native="$router.go(-1)" class="w-100">
                <button type="button" class="btn btn-cancel w-100">
                  <span>{{ translatedData?.cancelButtonText }}</span>
                </button>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const changePasswordJson = {
  'pageTitle': "Create new password",
  'pageDescription': "Enter a new password for your account",
  'newPasswordLabel': "New password",
  'confirmPasswordLabel': "Confirm new password",
  'passwordRequiredError': "Password field is required.",
  'passwordMaxLengthError1': "Maximum",
  'passwordMaxLengthError2': "characters are allowed.",
  'confirmPasswordRequiredError': "Confirm Password field is required.",
  'passwordMismatchError': "Password and Confirm Password fields does not match.",
  'changePasswordButtonText': "Change Password",
  'cancelButtonText': "Cancel",
  'eyeIconAltText': "Toggle password visibility"
};
import { mapActions, mapGetters } from "vuex";
import { required, maxLength, sameAs } from "vuelidate/lib/validators";
import { translationMixin } from "../mixins/translationMixin";
export default {
  name: "Change-Password",
  mixins: [translationMixin],
  data() {
    return {
      password: "",
      confirmPassword: "",
      signInBg: "",
      showPass: false,
      showCoPass: false,
      data:changePasswordJson
    };
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(25),
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },
  watch: {
    signInBg(newVal) {
      if (newVal) {
        let theBox = document.getElementById("change-password");

        theBox.style.backgroundImage = `url(${newVal})`;
        theBox.style.backgroundSize = "cover";
      }
    },
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;
    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters(["getOrgId", "getUserId", "getAssets", "getProspectEmail"]),
  },
  methods: {
    ...mapActions(["fetchLoaderStatus"]),

    async changePassword() {
      if (!this.getUserId) {
        this.err("You must login to change the password.");
        return;
      }

      this.fetchLoaderStatus(true);

      let payLoad = {
        email: "",
        password: this.password,
        prospectId: this.getUserId + "&&" + this.getProspectEmail,
        orgId: this.getOrgId,
      };

      await this.$http
        .post("/account/UpdateUser/", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success("Password updated successfully");

            this.password = "";
            this.confirmPassword = "";
            this.$v.$reset();

            setTimeout(() => {
              this.$router.go(-1);
            }, 1500);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchLoaderStatus(false);
    },
  },
};
</script>