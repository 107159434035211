<template>
  <div
    class="modal fade"
    id="infoModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="title">
            <h2>{{ title }}</h2>
          </div>
          <div class="content-desc">
            <p v-html="replacedDescription"></p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>{{ close }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InfoModal",
  data() {
    return {
      replacedDescription: "",
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    close: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  watch: {
    description: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.replacedDescription = newVal.replace(
            "{LO}",
            "<a>" + this.getUser.name + "</a>"
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.button-center {
  justify-content: center !important;
}

.modal#infoModal .modal-dialog {
  max-width: 520px;
}

.modal#infoModal .modal-dialog .modal-content {
  border-top: 5px solid var(--primary-color);
}

.modal#infoModal .modal-dialog .modal-content .modal-body {
  padding: 50px 35px;
}

.modal#infoModal .modal-dialog .modal-content .modal-body .title h2 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  color: #252c32;
  font-weight: 600;
}

.modal#infoModal .modal-dialog .modal-content .modal-body .content-desc {
  width: 100%;
  position: relative;
}

.modal#infoModal .modal-dialog .modal-content .modal-body .content-desc p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem;
  color: #252c32;
  opacity: 0.8;
  margin-top: 16px;
}
</style>
