<template>
  <div class="container" id="questionnaireContainer">
    <div :class="getShowInternalAuth ? 'custom-form-content' : 'form-content'">
      <template v-if="getShowInternalAuth">
        <Internal-Sign-In
          v-if="showLogin"
          @show-signup="showSignup"
          @show-forgotpass="showForgotPassword"
        />
        <Internal-Sign-Up v-if="showRegister" @show-signin="showSignin" />
        <Internal-Forgot-Password
          v-if="showForgotPass"
          @show-signin="showSignin"
          @show-signup="showSignup"
          @show-resetpass="showResetPassword"
        />
        <Internal-Reset-Password
          v-if="showResetPass"
          @show-signin="showSignin"
          @show-signup="showSignup"
        />
      </template>

      <template v-if="!getShowInternalAuth">
        <div class="form-element">
          <form v-for="(question, index) in getQuestions" :key="index">
            <!-- Picklist question type -->
            <picklist
              v-if="
                question.groupName === null &&
                question.questionType === 'Picklist' &&
                question.questionIndex === getActiveQuestion
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />

            <!-- LookUp question type -->
            <look-up
              v-else-if="
                question.questionType === 'Lookup' &&
                question.questionIndex === getActiveQuestion
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />

            <!-- Checkbox question type -->
            <check-box
              v-else-if="
                question.groupName === null &&
                question.questionType === 'Checkbox' &&
                question.questionIndex === getActiveQuestion
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />

            <!-- Radio question type -->
            <radio
              v-else-if="
                question.groupName === null &&
                question.questionType === 'Radio' &&
                question.questionIndex === getActiveQuestion
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />

            <!-- Multiple input field question types in a single form -->
            <form-fields
              v-else-if="
                question.questionIndex === getActiveQuestion &&
                question.apiName === 'LockLearProspectAuth' &&
                question.questionType !== 'Lookup'
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />

            <!-- View Addresses and Employment -->
            <template
              v-else-if="
                question.groupName !== null &&
                question.questionIndex === getActiveQuestion
              "
            >
              <view-addresses
                v-if="question.apiName === 'AddressAPI'"
                :question="question"
                :currentaq="currentAq"
                :previousaq="previousAq"
              />

              <view-employment
                v-if="question.apiName === 'EmploymentInfo'"
                :question="question"
                :currentaq="currentAq"
                :previousaq="previousAq"
              />
            </template>

            <!-- Multiple Choice question type -->
            <multiple-choice
              v-else-if="
                question.questionType === 'MultipleChoice' &&
                question.questionIndex === getActiveQuestion
              "
              :question="question"
              :currentaq="currentAq"
              :previousaq="previousAq"
            />
          </form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InternalSignIn from "./InternalSignIn.vue";
import InternalSignUp from "./InternalSignUp.vue";
import InternalForgotPassword from "./InternalForgotPassword.vue";
import InternalResetPassword from "./InternalResetPassword.vue";
import CheckBox from "./questiontypes/CheckBox.vue";
import FormFields from "./questiontypes/FormFields.vue";
import LookUp from "./questiontypes/LookUp.vue";
import MultipleChoice from "./questiontypes/MultipleChoice.vue";
import Picklist from "./questiontypes/Picklist.vue";
import Radio from "./questiontypes/Radio.vue";
import ViewAddresses from "./questiontypes/ViewAddresses.vue";
import ViewEmployment from "./questiontypes/ViewEmployment.vue";
import { addressTranslationMixin } from "../mixins/addressMxin";
import { employmentTranslationMixin } from "../mixins/employmentMixin";
export default {
  name: "Questionnaire",
  mixins: [addressTranslationMixin, employmentTranslationMixin],
  components: {
    InternalSignIn,
    InternalSignUp,
    InternalForgotPassword,
    InternalResetPassword,
    Picklist,
    LookUp,
    FormFields,
    MultipleChoice,
    CheckBox,
    ViewAddresses,
    ViewEmployment,
    Radio,
  },
  data() {
    return {
      currentAq: 0,
      previousAq: 0,
      showLogin: true,
      showRegister: false,
      showForgotPass: false,
      showResetPass: false,
    };
  },
  watch: {
    getActiveQuestion(newVal, oldVal) {
      this.scrollTop();

      this.currentAq = newVal;
      this.previousAq = oldVal || 0;

      if (newVal) {
        if (newVal === "" || newVal < 1) {
          this.fetchActiveQuestion(1);
        }
        this.fetchActiveSection(this.getQuestions[newVal - 1].sectionName);
      }
    },
  },
  created() {
    if (!this.getQuestions.length) this.$router.push(this.baseUrl);
  },
  async mounted() {
    await this.getQuestionnaire();
    this.fetchLoaderStatus(false);

    if (this.getUserId) {
      this.getMultipleChoiceData();
      this.getAddresses();
      this.getEmploymentInfo();
    }

    if (
      (this.getActiveQuestion === "" || this.getActiveQuestion < 1) &&
      this.getQuestions.length
    ) {
      this.fetchActiveQuestion(1);
    }

    this.$nextTick(() => {
      if (this.getActiveSection === "" && this.getQuestions.length) {
        let getSectionName = this.getQuestions.find(
          (v) => v.questionIndex === this.getActiveQuestion
        );
        this.fetchActiveSection(getSectionName.sectionName);
      }
    });
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getCompanyName",
      "getProspectId",
      "getOrgId",
      "getActiveQuestion",
      "getActiveSection",
      "getQuestions",
      "getShowInternalAuth",
      "getAppFlow",
      "getSubQuestions",
      "getApplyAgainFlag",
      "getEditModeFlag",
      "getSubjectPropertyStatus",
      "getActivePage",
    ]),

    baseUrl() {
      return (
        "/" +
        this.getCompanyName +
        "/" +
        this.getProspectId +
        "&" +
        this.getOrgId
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchAllAddresses",
      "fetchAllMcQuestions",
      "fetchAllEmployment",
      "fetchQuestions",
      "fetchSubQuestions",
      "fetchActiveSection",
      "fetchActiveQuestion",
      "fetchLoaderStatus",
      "fetchSections",
      "fetchApplyAgainFlag",
      "fetchSubjectPropertyStatus",
    ]),

    showSignup(data) {
      this.showRegister = data;
      this.showLogin = !data;
      this.showForgotPass = !data;
      this.showResetPass = !data;
    },

    showSignin(data) {
      this.showLogin = data;
      this.showRegister = !data;
      this.showForgotPass = !data;
      this.showResetPass = !data;
    },

    showForgotPassword(data) {
      this.showForgotPass = data;
      this.showLogin = !data;
      this.showRegister = !data;
      this.showResetPass = !data;
    },

    showResetPassword(data) {
      this.showResetPass = data;
      this.showLogin = !data;
      this.showRegister = !data;
      this.showForgotPass = !data;
    },

    async getQuestionnaire() {
      let user_id = this.getUserId || null;

      await this.$http
        .get(
          "/question/GetProspectQuestions/" +
            this.getAppFlow +
            "/" +
            user_id +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchSections(resp.sidebarSections);
            this.fetchQuestions(resp.appQuestions);
            this.fetchSubQuestions(resp.subQuestions);

            this.redirectUserWhereLeftOff(resp.lastAnsweredQuestionId);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    redirectUserWhereLeftOff(data) {
      if (this.getApplyAgainFlag) {
        this.fetchActiveQuestion(1);

        this.$nextTick(() => {
          if (this.getActiveSection === "" && this.getQuestions.length) {
            let getSectionName = this.getQuestions.find(
              (v) => v.questionIndex === this.getActiveQuestion
            );

            this.fetchActiveSection(getSectionName.sectionName);
          }

          if (this.getApplyAgainFlag) this.fetchApplyAgainFlag(false);
        });
        return;
      }

      let theFields = [];
      let lastAttendedQue = null;
      let lastQueAnsweredId = "";

      if (data) {
        lastQueAnsweredId = data.split("-");

        lastAttendedQue = this.getQuestions.find(
          (k) => k.questionId === lastQueAnsweredId[0]
        );
      }

      if (lastAttendedQue) {
        let lastQueIndex;

        // Check if Co-Applicant is added
        let isCoApplicantAdded = this.getQuestions.some(
          (x) =>
            (x.questionFieldMapping === "is_joint_applicant_added__c" ||
              x.questionFieldMapping ===
                "candidoem__is_joint_applicant_added__c") &&
            x.questionAnswer === "Yes"
        );

        if (isCoApplicantAdded) {
          lastQueIndex = this.getQuestions.at(-1).questionIndex;
        }

        if (!isCoApplicantAdded) {
          let theQues = this.getQuestions.filter(
            (j) => !j.dependentQuestionId && !j.dependentQuestionAnswer
          );

          lastQueIndex = theQues.at(-1).questionIndex;

          if (lastQueAnsweredId[0] === theQues.at(-1).questionId) {
            lastAttendedQue = theQues.at(-1);
          }
        }

        // If last question is answered then redirect user to Submit Application page
        // else redirect user to specific question
        if (
          lastAttendedQue.questionIndex === lastQueIndex &&
          !this.getEditModeFlag
        ) {
          if (!this.getActivePage) {
            this.$router.push("/submit-application");
          }
        } else {
          this.getQuestions.forEach((v) => {
            if (
              v.questionIndex > lastAttendedQue.questionIndex &&
              v.questionType === "Checkbox"
            ) {
              v.questionAnswer = null;
              v.coQuestionAnswer = null;
            }
          });

          if (lastAttendedQue.groupName) {
            for (let i = lastAttendedQue.questionIndex - 1; i > 0; i--) {
              if (
                this.getQuestions[i].groupName === null ||
                this.getQuestions[i].groupName !== lastAttendedQue.groupName
              ) {
                break;
              }
              theFields.push(this.getQuestions[i]);
            }

            this.fetchActiveQuestion(theFields.at(-1).questionIndex);
          }

          if (
            !lastAttendedQue.groupName &&
            this.getActivePage &&
            (lastAttendedQue.questionIndex === 158 ||
            lastAttendedQue.questionIndex === 149
              ? true
              : false)
          ) {
            this.fetchActiveQuestion(1);
            this.fetchActiveSection("Getting Started");
          } else {
            this.fetchActiveQuestion(lastAttendedQue.questionIndex);
            this.fetchActiveSection(lastAttendedQue.sectionName);
          }
          // if(!lastAttendedQue.groupName)
          // this.fetchActiveQuestion(lastAttendedQue.questionIndex);
          //   this.fetchActiveSection(lastAttendedQue.sectionName);
        }
      }

      if (!lastAttendedQue) {
        this.getQuestions.forEach((v) => {
          if (v.questionType === "Checkbox" && v.needsAuthentication) {
            v.questionAnswer = null;
            v.coQuestionAnswer = null;
          }
        });
      }
    },

    getMultipleChoiceData() {
      this.$http
        .get(
          "/multipleChoice/getMultipleChoiceQuestionData?prospectId=" +
            this.getUserId +
            "&flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            let mainQues = resp.mainQuestions;
            let subQues = resp.subQuestions;

            this.getQuestions.forEach((c) => {
              let findQue = mainQues.find(
                (x) => x.mainQuestion.questionId === c.questionId
              );

              if (findQue) {
                c.questionAnswer = findQue.options;
              } else {
                if (c.questionType === "MultipleChoice") c.questionAnswer = "";
              }
            });

            this.fetchQuestions(this.getQuestions);

            let theSubs = this.getSubQuestions.map((y) => {
              let findSubQue = subQues.find(
                (z) => z.questionId === y.questionId
              );

              if (findSubQue) y = findSubQue;
              else y.questionAnswer = "";

              return y;
            });
            const translatedtheSubs =
              (await this.translateSubQuestions(theSubs)) || translatedtheSubs;

            this.fetchSubQuestions(translatedtheSubs);

            let payLoad = {
              mainQuestions: mainQues,
              subQuestions: subQues,
            };

            this.fetchAllMcQuestions(payLoad || {});
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async translateSubQuestions(theSubs) {
      // Get the target language based on the app language
      const targetLanguage = this.getAppLanguage;

      // Collect all questionAnswer values from the sub-questions for translation
      const questionAnswersToTranslate = [];
      const questionMapping = [];

      // Iterate over the sub-questions to collect all the questionAnswer texts that need translation
      theSubs.forEach((sub) => {
        if (sub.questionAnswer && typeof sub.questionAnswer !== "object") {
          // Ensure it's not an object (e.g., an array or nested structure)
          questionAnswersToTranslate.push(sub.questionAnswer);
          questionMapping.push(sub); // Track the reference to the original sub-question
        }
      });
      // If there are question answers to translate, perform batch translation
      if (questionAnswersToTranslate.length > 0) {
        try {
          // Perform batch translation for all collected questionAnswer texts
          const translatedAnswers = await this.translateTexts(
            questionAnswersToTranslate,
            [],
            targetLanguage
          );
          // Map the translated answers back to their corresponding sub-questions
          translatedAnswers.forEach((translatedAnswer, index) => {
            const subQuestion = questionMapping[index];
            if (subQuestion) {
              subQuestion.translatedQuestionAnswer =
                translatedAnswer || subQuestion.questionAnswer; // Use translated answer or fallback to original
            }
          });
        } catch (error) {
          console.error("Translation failed:", error);
        }
      }
      return theSubs; // Return the updated sub-questions with translated questionAnswer
    },

    getAddresses() {
      this.$http
        .get(
          "/address/GetAllAddress/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;
          const translatedAddress = await this.translateAddressQuestions(
            resp.addressData,
            false
          );

          if (resp.response === "Success") {
            this.fetchAllAddresses(translatedAddress || []);

            if (this.getAppFlow === "Refinance" && resp.addressData?.length) {
              let is_subject_property = resp.addressData.some(
                (c) => c.hasSubjectProperty
              );

              this.fetchSubjectPropertyStatus(is_subject_property);
            }

            if (this.getAppFlow === "Buying" && this.getSubjectPropertyStatus)
              this.fetchSubjectPropertyStatus(false);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getEmploymentInfo() {
      this.$http
        .get(
          "/employmentInfo/GetAllEmploymentInfo/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;
          const translatedEmploymentInfoData =
            await this.translateEmploymentQuestions(response?.data, false);
          if (resp.response === "Success") {
            this.fetchAllEmployment(
              translatedEmploymentInfoData?.employmentInfoData || []
            );
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    scrollTop() {
      let element = document.getElementById("questionnaireContainer");
      element.scrollIntoView();
    },
  },
};
</script>

<style scoped>
.custom-form-content {
  align-items: unset;
}
</style>
